import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { useMediaQuery } from 'react-responsive';
import { posthog } from 'posthog-js';

import Icon from '../elements/Icon';
import { useUserContext } from '../UserContext';
import HelpPage from '../pages/HelpPage';
import AboutPage from '../pages/AboutPage';



const AppMenu = ({  isCollapsed, setIsCollapsed, activeView, setActiveView }) => {
  
  const { user, userId, subscriptionStatus, trialEnd, isFreeTier, userPhoto } = useUserContext();
  const [isExpanded, setIsExpanded] = useState(false);
  const [showMenuText, setShowMenuText] = useState(!isCollapsed);

  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

  const toggleCollapse = () => {
    if (isCollapsed) {
      setIsCollapsed(false);
      setTimeout(() => setShowMenuText(true), 300);
    } else {
      setShowMenuText(false);
      setIsCollapsed(true);
    }
  };

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
    if (!isExpanded) {
      setTimeout(() => setShowMenuText(true), 300);
    } else {
      setShowMenuText(false);
    }
  };

  const menuItems = [
    {  viewName: "Search", icon: "search", text: "Quick Search" },
    {  viewName: "Library", icon: "building-columns", text: "My Libraries" },
    {  viewName: "LibrarySearch", icon: "books", text: "Library Search" },
    {  viewName: "Bookmarks", icon: "bookmark", text: "Bookmarks" },
  ];

  const menuInfoStyle = `block ${isCollapsed ? 'text-lg text-center' : 'text-sm'}  text-white hover:underline hover:text-white`;

  const onLinkClick = (viewName) => {
        setActiveView(viewName);

        posthog.capture('active_view', { viewName: viewName });
        if (isMobile) {
          setIsExpanded(false);
        }
    }

  const menuLinks = () => {
    return menuItems.map((item) => (
        <Link
          key={item.viewName}
          onClick={() => onLinkClick(item.viewName)}
          className={`
            text-white block py-2 px-4 hover:bg-gray-100 ${isCollapsed ? '' : 'rounded'} 
            transition duration-150 ease-in-out
            ${isCollapsed ? 'text-center' : ''}
            ${isMobile ? 'text-center' : ''}
            ${!isCollapsed ? 'active:scale-95' : ''}
            ${activeView === item.viewName ? 'bg-gray-100/10 border-l-2 border-white rounded-l-none' : ''}
            `}
        >
            <Icon icon={item.icon} style="solid"
            className={`${isCollapsed ? '' : 'mr-4'} `} />
            {showMenuText && (
              <motion.span
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.3 }}
              >
                {item.text}
              </motion.span>
            )}
        </Link>
    ))
  }

  const tierText = () => {
    if (subscriptionStatus === 'trialing') {
      const trialEndDate = new Date(trialEnd * 1000).toLocaleDateString();
      return (
        <div>
          <p className="text-sm text-white bg-secondary-dark font-semibold px-2 py-0 rounded text-center">Pro Trial</p>
          <p className="text-xs text-white mt-1 text-center">Until {trialEndDate}</p>
        </div>
      );
    } else if (isFreeTier) {
      return <p className="text-sm text-white bg-accent font-semibold px-2 py-0 rounded text-center">Free Tier</p>;
    } else {
      return <p className="text-sm text-white bg-secondary-dark font-semibold px-2 py-0 rounded text-center">LitSearch Pro</p>;
    }
  }

  const menuContent = (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.3 }}
      className={`flex flex-col h-full justify-between items-flex-start`}
    >
      <div className="space-y-">
        {/* USER */}
        <div 
            className={`
                user-section flex items-center 
                text-white border-b border-white 
                py-2 cursor-pointer
                ${ !isCollapsed ? 'pl-4' : 'pl-3'}
                ${isMobile ? 'justify-center' : ''}
                space-x-4
                hover:bg-white/10 active:bg-white/20
                select-none
            `}
        >
          {/* <UserButton 
            appearance={{
              elements: {
                userButtonAvatarBox: `w-10 h-10 rounded-full border border-white`,
              }
            }}
          /> */}
          <img src={userPhoto} alt={`profile pic`} className="user-photo w-10 h-10 rounded-full border border-white" onClick={() => onLinkClick('Account')} />
          {showMenuText && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.3 }}
              className="flex flex-col cursor-pointer"
              onClick={() => onLinkClick('Account')}
            >
              <p className="font-semibold">{user.firstName} {user.lastName}</p>
              {tierText()}
            </motion.div>
          )}
        </div>

        {/* MENU */}
        <nav className={`menu-buttons flex-grow flex flex-col justify-center mt-20 overflow-hidden ${isCollapsed ? 'text-lg' : ''}`}>
           {menuLinks()}
        </nav>
      </div>

        {/* INFO */}  
        <div className={`info-section space-y-2 ${isCollapsed ? '' : 'pl-4'} ${isMobile ? 'mx-auto' : ''}`}>
        <div onClick={() => window.location.href = '/help'} className={`${menuInfoStyle} hover:cursor-pointer hover:underline`}>
            {isCollapsed ? <Icon icon="question-circle" /> : 'Help'}
        </div> 
        <div onClick={() => window.location.href = '/about'} className={`${menuInfoStyle} hover:cursor-pointer hover:underline`}>
            {isCollapsed ? <Icon icon="info-circle" /> : 'About'}
        </div>
        </div>
    
    </motion.div>
  );

  return (
    <>
      {/* Mobile menu */}
      <div className="md:hidden z-[9999]">
        <motion.button 
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.9 }}
          onClick={toggleExpand}
          className="fixed top-2 left-1 z-[9999] p-2 rounded-full shadow-md bg-primary-dark text-white border-2 border-white"
        >
          <Icon icon={isExpanded ? "times" : "bars"} />
        </motion.button>

        <AnimatePresence>
          {isExpanded && (
            <motion.div
              initial={{ x: '-100%' }}
              animate={{ x: 0 }}
              exit={{ x: '-100%' }}
              transition={{ type: 'spring', stiffness: 300, damping: 30 }}
              className="fixed inset-0 bg-primary-dark z-40 p-6"
            >
              {menuContent}
            </motion.div>
          )}
        </AnimatePresence>
      </div>

      {/* Desktop menu */}
      <motion.div
        initial={false}
        animate={{ width: isCollapsed ? 80 : 256 }}
        transition={{ type: 'spring', stiffness: 300, damping: 30 }}
        className="hidden md:block fixed top-0 left-0 h-full bg-white shadow-lg"
      >
        <div className={`h-full bg-primary-dark ${isCollapsed ? 'px-0' : 'px-4'} pt-4 pb-6`}>
          {menuContent}
        </div>
        <motion.button 
          whileTap={{ scale: 0.9 }}
          className="toggle absolute bottom-[20%] -right-3 bg-primary-dark border-2 border-white text-white py-1.5 px-1 rounded-lg hover:bg-primary-light hover:text-primary-dark transition-all duration-300"
          onClick={toggleCollapse}
        >
          <Icon icon={isCollapsed ? "chevron-right" : "chevron-left"} style="solid"/>
        </motion.button>
      </motion.div>
    </>
  );
};

export default AppMenu;
