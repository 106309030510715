

const UsageLimitsFree = {
    myLibraries: 1,
    maxLibrarySize: 100,
    quickSearches: 4,
    librarySearches: 4,
    smartSort: 2,
    exportRecommendations: false,
    weeklyRecommendations: false,
    bookmarks: 20,
}


const UsageLimitsPro = {
    myLibraries: Infinity,
    maxLibrarySize: Infinity,
    quickSearches: Infinity,
    librarySearches: Infinity,
    smartSort: Infinity,
    exportRecommendations: "Export recommendations",
    weeklyRecommendations: "Weekly recommendations",
    bookmarks: Infinity,
}

export { UsageLimitsFree, UsageLimitsPro };