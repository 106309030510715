import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { toast } from 'react-toastify';
import posthog from 'posthog-js';

import fetchLibraries from '../scripts/FetchUserLibraries';
import ViewContainer from '../elements/ViewContainer';
import sendPostRequest from '../scripts/Requests';
import SelectLibrary from '../elements/SelectLibrary';
import { CreateNewLibraryFromFileModal, useLibraryLimitCheck } from '../components/LibraryCreationModals';
import { useUserContext } from '../UserContext';
import LibraryDisplay, { LibraryDisplayPlaceholder } from '../components/LibraryDisplay';
import PapersGraph from '../elements/PapersGraph';


/* -------------------------------------------------------------------------- */
/*                               LIBRARY VIEW                                 */
/* -------------------------------------------------------------------------- */

const LibraryView = ({ setActiveView, isActive }) => {
    const { userId, subscriptionTier, libraries, setLibraries } = useUserContext();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedLibrary, setSelectedLibrary] = useState(null);
    const { checkLibraryLimit, isLoading: isLibraryCountLoading, libraryCount } = useLibraryLimitCheck(userId, subscriptionTier);
    const [creatingLibrary, setCreatingLibrary] = useState(null);
    
    const [references, setReferences] = useState([]);


    useEffect(() => {
        if (isActive) {
            fetchLibraries(userId, subscriptionTier, setLibraries, 'LibraryView');
        }
    }, [userId, isActive, subscriptionTier, setLibraries]);

    const handleAddLibraryClick = () => {
        if (checkLibraryLimit()) {
            startLibraryCreation();
            posthog.capture('add_library_clicked', { subscription_tier: subscriptionTier });
        } else {
            setActiveView('Account');
            posthog.capture('library_limit_reached', { subscription_tier: subscriptionTier });
        }
    };

    const startLibraryCreation = () => {
        setIsModalOpen(true);
    };

    const handleLibraryCreation = async (file, name) => {
        setCreatingLibrary(name);
        const formData = new FormData();
        formData.append('file', file);
        formData.append('user_id', userId);
        formData.append('library_name', name);
        formData.append('subscription_tier', subscriptionTier);
        formData.append('is_free_tier', subscriptionTier === 'Free');

        const [response, success] = await sendPostRequest(
            '/backend/libraries',
            formData,
            'create-library'
        );

        if (!success) {
            toast.error(response.error || "There was an error creating the library. Please try again or contact support.");
            posthog.capture('library_creation_failed', { error: response.error });
        } else {
            toast.success('Library created successfully');
            posthog.capture('library_created', { name: name, subscription_tier: subscriptionTier });
            fetchLibraries(userId, subscriptionTier, setLibraries, 'LibraryView');
        }

        setIsModalOpen(false);
        setCreatingLibrary(null);
    };

    const handleLibraryCreated = () => {
        fetchLibraries(userId, subscriptionTier, setLibraries, 'LibraryView');
    };

    const handleSelectLibrary = (library) => {
        setSelectedLibrary(library);
    };

    const containerVariants = {
        hidden: { opacity: 0 },
        visible: {
            opacity: 1,
            transition: {
                when: "beforeChildren",
                staggerChildren: 0.2
            }
        }
    };

    const itemVariants = {
        hidden: { opacity: 0, y: 20 },
        visible: {
            opacity: 1,
            y: 0,
            transition: {
                duration: 0.5
            }
        }
    };

    return (
        <ViewContainer title="Manage your libraries" icon="building-columns">
            <motion.div 
                className="relative lg:flex lg:flex-row"
                initial="hidden"
                animate="visible"
                variants={containerVariants}
            >
                <div className="relative z-40 lg:w-1/4 mb-12">
                    <motion.div 
                        variants={itemVariants}
                    >
                        <SelectLibrary 
                            libraries={libraries} 
                            onButtonClick={handleAddLibraryClick} 
                            selectedLibrary={selectedLibrary}
                            onSelectLibrary={handleSelectLibrary}
                            subscriptionTier={subscriptionTier}
                            setActiveView={setActiveView}
                            libraryCount={libraryCount}
                            isLibraryCountLoading={isLibraryCountLoading}
                            libraryBeingCreated={creatingLibrary}
                        />

                        <div className="w-full h-full mt-8 mb-8">
                        <PapersGraph 
                            recommendations={[]}
                            references={references}
                            stackTitle={false}
                            title="Your papers"
                            hideAttributeSelection={true}
                            wide={true}
                        />
                        </div>
                    </motion.div>
                </div>

                <div 
                    className="lg:w-3/4 z-0"
                >
                    {selectedLibrary ? (
                        <LibraryDisplay 
                            key={selectedLibrary.id}
                            userId={userId}
                            subscriptionTier={subscriptionTier}
                            selectedLibrary={selectedLibrary} 
                            actionCallback={() => fetchLibraries(userId, subscriptionTier, setLibraries, 'LibraryView')}
                            setSelectedLibrary={setSelectedLibrary}
                            setActiveView={setActiveView}
                            isActive={isActive}
                            references={references}
                            setReferences={setReferences}
                        />
                    ) : (
                        <LibraryDisplayPlaceholder />
                    )}
                </div>
            </motion.div>

            {isModalOpen &&
                <CreateNewLibraryFromFileModal 
                    onClose={handleLibraryCreated} 
                    onUpload={handleLibraryCreation} 
                    isOpen={isModalOpen} 
                    setIsOpen={setIsModalOpen}
                />
            }
        </ViewContainer>
    );
};

export default LibraryView;
