import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { toast } from 'react-toastify';
import posthog from 'posthog-js';

import { TooltipButton } from '../elements/Buttons';
import Icon from '../elements/Icon';
import { useUserContext } from '../UserContext';
import AddToLibrarySelector from './AddToLibrarySelector';
import sendPostRequest from '../scripts/Requests';

const iconButtonStyle = `
    border-b-2
    z-50
    px-2
`


const SummaryAndExplanationDisplay = ({ summary, explanation }) => {
    const variants = {
        hidden: { opacity: 0, y: -20, height: 0 },
        visible: { opacity: 1, y: 0, height: 'auto' },
        exit: { opacity: 0, y: 20, height: 0 }
    };

    if (summary === '' && explanation === '') {
        return (
            <motion.p
                variants={variants}
                initial="hidden"
                animate="visible"
                exit="exit"
                transition={{ duration: 0.5 }}
                className="px-2 relative mt-2 mb-4 text-s text-gray-500"
            >
                <Icon icon="robot" className="mr-2" /> Summarizing paper abstract...
            </motion.p>
        );
    }

    return (
        <motion.div
            variants={variants}
            initial="hidden"
            animate="visible"
            exit="exit"
            transition={{ duration: 0.5 }}
            className="px-2 relative mt-1 mb-4"
        >
            <p className="font-semibold  text-sm text-gray-800 mb-1 text-left">Summary</p>
            <p className="text-gray-800 text-left text-xs leading-4 text-justify whitespace-pre-wrap break-words ">{summary}</p>
            <p className="font-semibold  text-sm text-gray-800 mb-1 mt-4 text-left">Explanation</p>
            <p className="text-gray-800 text-left text-xs leading-4 text-justify whitespace-pre-wrap break-words ">{explanation}</p>
        </motion.div>
    )
}


/* -------------------------------------------------------------------------- */
/*                           PAPER DETAILS CONTROLS                           */
/* -------------------------------------------------------------------------- */

const PaperDetailsControls = ({
    paper, 
    onBookmarkPaper, 
    isMobile, 
    url, 
    isBookmarked, 
    showButtons=true, 
    showLibrarySelector, 
    setShowLibrarySelector,
    searchQuery=null,
    handleSummarizeAndExplain,
    isLibraryPaper
}) => {
    const { userId } = useUserContext();
    if (isLibraryPaper) {
        return <p className="text-gray-500 text-xs text-right pr-2 my-2">
            This paper is in your library <Icon icon="building-columns"  />
        </p>;
    }

        
    return (
        <div className={`
                    flex flex-row items-center 
                    justify-end gap-2 pr-4 mb-2 
                    ${isMobile ? 'bg-white rounded-lg px-2 py-1' : ''}
                    ${showButtons ? 'opacity-100' : 'opacity-0'}
                `}>

            {searchQuery && <TooltipButton
                  icon="message-lines"
                  iconStyle="light"
                  onClick={handleSummarizeAndExplain}
                  tooltip="Summarize abstract and explain relevance"
                  className={`papers-display-controls-button ${iconButtonStyle}`}
            />}

            <TooltipButton
                  icon="circle-plus"
                  onClick={() => {
                    if (!userId) {
                        toast.warning('You must be logged in to use this feature');
                        return;
                    }
                    setShowLibrarySelector(!showLibrarySelector)
                }}
                  tooltip="Add selected papers to the library"
                  className={`papers-display-controls-button ${iconButtonStyle}`}
            />

            {url && (
                <TooltipButton 
                    icon='link' 
                    onClick={() => {
                        window.open(url, '_blank');
                        posthog.capture('open_paper_url', { paperId: paper.paperId, title: paper.title });
                    }} 
                    tooltip="Open paper in new tab" 
                    className={`papers-display-controls-button ${iconButtonStyle}`}
                />
            )}

            <TooltipButton 
                icon='bookmark'
                onClick={() => {
                    onBookmarkPaper();
                    posthog.capture('toggle_bookmark', { paperId: paper.paperId, title: paper.title, action: isBookmarked ? 'remove' : 'add' });
                }} 
                tooltip={isBookmarked ? "Remove bookmark" : "Bookmark this paper"} 
                iconStyle={isBookmarked ? 'solid' : 'light'}
                iconClassName={isBookmarked ? 'text-secondary' : ''}
                className={`
                    papers-display-controls-button 
                    
                    ${iconButtonStyle}
                `}
            />

        </div>
    )
}


/* -------------------------------------------------------------------------- */
/*                                PAPER DETAILS                               */
/* -------------------------------------------------------------------------- */
const PaperDetails = ({ paper, isMobile, showButtons=true, isLibraryPaper=false, selectedLibraries, setSelectedLibraries, searchQuery=null }) => {
    const { userId, isFreeTier, bookmarks, toggleBookmark } = useUserContext();
    const [isBookmarked, setIsBookmarked] = useState(false);
    const [showLibrarySelector, setShowLibrarySelector] = useState(false);
    const [summary, setSummary] = useState('');
    const [explanation, setExplanation] = useState('');
    const [showSummaryAndExplanation, setShowSummaryAndExplanation] = useState(false);

    useEffect(() => {
        // Check if the paper is bookmarked when the component mounts or when the paper or bookmarks change
        setIsBookmarked(bookmarks.some(bookmark => bookmark.paperId === paper.paperId));
    }, [paper, bookmarks]);

 

    const handleBookmarkPaper = async () => {
        if (!userId) {
            toast.warning('You must be logged in to bookmark papers');
            return;
        }
        try {
            await toggleBookmark(paper);
            posthog.capture('toggle_bookmark', { paperId: paper.paperId, title: paper.title, action: isBookmarked ? 'remove' : 'add' });
        } catch (error) {
            if (error === 'Bookmark limit reached for free tier users') {
                toast.error('You have reached the bookmark limit for your tier. Upgrade to LitSearch Pro to unlock unlimited bookmarks.');
            } else {
                toast.error('Error bookmarking paper, please try again later');
            }
        }
    };

    const handleSummarizeAndExplain = async () => {
        if (showSummaryAndExplanation) {
            setShowSummaryAndExplanation(false);
            return;
        }

        if (!userId) {
            toast.warning('You must be logged in to use this feature');
            return;
        }

        // avoid repeated requests
        if (summary !== '' || explanation !== '') {
            setShowSummaryAndExplanation(true);
            return;
        }

        setShowSummaryAndExplanation(true);
        const [response, success, error] = await sendPostRequest('/backend/summarize-and-explain', {
            title: paper.title,
            abstract: paper.abstract,
            search_query: searchQuery,
            score: paper.score
        },
        'summarize-and-explain'
        );

        if (success) {
            setSummary(response.summary);
            setExplanation(response.explanation);
            
        } else {
            toast.error('Error summarizing and explaining paper, please try again later');
            setShowSummaryAndExplanation(false);
            console.error(error)
        }
    };

    const _score = paper.score ? Math.round(paper.score * 100) : null
    const scoreColor = _score ? _score > 80 ? "text-accent" : "text-color" : "text-color"

    const hasUrl = paper.url && paper.url.length > 0

    return (
        <motion.div
            className="flex flex-col max-h-[100vh] group"
            initial={{ opacity: 0, x: 25 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: -25 }}
            transition={{ duration: 0.15 }}
        >
            <PaperDetailsControls 
                paper={paper}
                onBookmarkPaper={handleBookmarkPaper} 
                isMobile={isMobile}
                url={paper.url}
                isBookmarked={isBookmarked}
                showButtons={showButtons}
                showLibrarySelector={showLibrarySelector}
                setShowLibrarySelector={setShowLibrarySelector}
                searchQuery={searchQuery}
                handleSummarizeAndExplain={handleSummarizeAndExplain}
                isLibraryPaper={isLibraryPaper}
            />

            {showSummaryAndExplanation && <SummaryAndExplanationDisplay summary={summary} explanation={explanation} />}
        
            <AnimatePresence>
                {showLibrarySelector && (
                    <motion.div
                        initial={{ opacity: 0, y: -20 }}
                        animate={{ opacity: 1, y: 0 }}
                        exit={{ opacity: 0, y: -20 }}
                        transition={{ duration: 0.2 }}
                    >
                        <AddToLibrarySelector
                            paper={paper}
                            onClose={() => setShowLibrarySelector(false)}
                            selectedLibraries={selectedLibraries}
                            setSelectedLibraries={setSelectedLibraries}
                        />
                    </motion.div>
                )}
            </AnimatePresence>

            <motion.div
                key={paper.paperId}
                initial={{ opacity: 0, x: 25 }}
                animate={{ opacity: 1, x: 0 }}
                exit={{ opacity: 0, x: -25 }}
                transition={{ duration: 0.15 }}
                className="rounded-lg border bg-white overflow-y-auto no-scrollbar p-6 hover:drop-shadow-lg"
            >
                {/* Title and Authors */}
                <h2 className={`text-xl font-bold mb-4 leading-7 text-center ${hasUrl ? 'cursor-pointer hover:text-secondary' : ''} transition-colors duration-300`}
                    onClick={hasUrl ? () => {
                        window.open(paper.url, '_blank');
                        posthog.capture('open_paper_url_from_title', { paperId: paper.paperId, title: paper.title });
                    } : null}
                >
                    {paper.title}
                </h2>
                <p className="text-gray-600 mb-4 text-left">
                    <Icon icon="users" className="mr-3 text-secondary/60 text-sm" />
                    {paper.authors}
                </p>
                <p className="text-gray-500 italic mb-4 text-left">
                    <Icon icon="newspaper" className="mr-3 text-secondary/60 text-sm" />
                    {paper.journal}
                </p>

                {/* Score  */}
                <div className="flex space-x-4 mb-4 text-center flex-row justify-center items-center select-none text-sm">
                {_score  && <div className="font-semibold">
                    <Icon icon="fire" className="mr-2 text-accent" />
                    <span className={scoreColor}>{_score}</span><span className="text-sm font-light">/100</span>
                </div>}
                <div><Icon icon="chart-line" className="mr-2 text-secondary" /> <span className="font-semibold">{paper.n_citations}</span> citations</div>
                <div><Icon icon="calendar" className="mr-2 text-secondary" /> <span className="font-semibold">{paper.year}</span></div>
                </div>

                <p className="text-lg font-semibold text-gray-800 mb-2 mt-8 text-left">Abstract</p>
                <p className="text-gray-800 text-left text-sm leading-5 text-justify whitespace-pre-wrap break-words ">{paper.abstract}</p>
            </motion.div>
    </motion.div>
)};

const PaperDetailsPlaceholder = ({ isLoading }) => {
    const componentTransition = {
        initial: { opacity: 0.5 },
        animate: { opacity: isLoading ? [1, 0.5, 1] : 1 },
        transition: isLoading ? { duration: 3, repeat: Infinity,  repeatDelay: 4 } : { duration: 0.3 }
    }

    return (
        <motion.div
            className="space-y-4 bg-white rounded-lg p-6 border"
            initial={{ scale: 0.95 }}
            animate={isLoading ? { scale: [1, 1.02, 1] } : { scale: 1 }}
            transition={isLoading ? { duration: 3, repeat: Infinity,  repeatDelay: 4 } : { duration: 0.3 }}
        >
            <motion.div className="w-3/4 h-8 bg-gray-300 rounded" {...componentTransition} />
            <motion.div className="w-1/2 h-4 bg-gray-300 rounded" {...componentTransition} />
            <div className="flex space-x-4">
                <motion.div className="w-20 h-6 bg-gray-300 rounded" {...componentTransition} />
                <motion.div className="w-24 h-6 bg-gray-300 rounded" {...componentTransition} />
                <motion.div className="w-16 h-6 bg-gray-300 rounded" {...componentTransition} />
            </div>
            <div className="space-y-2 mt-10">
                {[...Array(12)].map((_, index) => (
                    <motion.div 
                        key={index}
                        className={`w-${['11/12', 'full', '5/6', '3/4', '10/12', '4/5', '11/12', '9/10', '7/8', 'full', '5/6', '11/12'][index]} h-4 bg-gray-200 rounded ${index === 0 ? 'mt-10' : ''}`}
                        {...componentTransition}
                    />
                ))}
            </div>
        </motion.div>
    )
};

export default PaperDetails;
export { PaperDetailsPlaceholder, PaperDetailsControls }
