import React from "react";

import Icon from "./Icon";


const LabelledText = ({ icon, text }) => {
    return ( 
        <p className="inline-flex items-center mx-auto text-center mb-0 mt-3 highlight-border font-semibold text-md group-hover:text-secondary transition-colors duration-300">
            <Icon icon={icon} className="mr-0.5" /> {text}
        </p>
);
}

const ExplainerSection = ({ title, blurb, listItems, isCenter=false }) => {
    const border = isCenter ? "border-x  border-gray-400 px-4" : "px-4";
    return (
    <div className={`py-2  ${border} w-full`}>
        <h3 className="text-lg font-semibold mb-2 text-gray-500 text-center group-hover:text-gray-600 transition-colors duration-300">{title}</h3>
        <p className="text-sm mb-1 text-center">{blurb}</p>
        <div className="text-xs">
            {listItems.map((item, index) => (
                <div key={index}>
                    <LabelledText icon={item.icon} text={item.text} /> - {item.blurb}
                </div>
            ))}
        </div>
    </div>
);
}

const LandingResultsExplainer = () => {
    return (
        <div className=" group select-none cursor-default my-8 w-full bg-primary-dark/10 py-4 border-y border-gray-300">
            <div className="max-w-7xl w-10/12 mx-auto">
            <h2 className="text-2xl font-bold text-secondary text-center mb-4">Welcome to Your Search Results</h2>
            <div className="grid grid-cols-1 md:grid-cols-3">
                <ExplainerSection 
                    title="Exploring Results"
                    blurb="Discover and analyze papers with ease."
                    listItems={[
                        { icon: "fire", text: "Relevance", blurb: "see how closely papers match your search." },
                        { icon: "calendar", text: "Year", blurb: "check publication dates at a glance." },
                        { icon: "chart-line", text: "Citations", blurb: "gauge the impact of papers in the field." },
                        { icon: "sort", text: "Sort", blurb: "organize results based on your preferences." }
                    ]}
                />
                <ExplainerSection 
                    title="Refining Results"
                    blurb="Narrow down your search."
                    listItems={[
                        { icon: "chart-network", text: "Citation Network", blurb: "explore connections between papers." },
                        { icon: "filter", text: "Topic Filters", blurb: "focus on specific areas of interest." },
                        { icon: "sliders", text: "Adjustable Sliders", blurb: "fine-tune search criteria." },
                        { icon: "file-export", text: "Export", blurb: "export your search results." }
                    ]}
                    isCenter={true}
                />
                <ExplainerSection 
                    title="Managing Discoveries"
                    blurb="Efficiently handle your research findings."
                    listItems={[
                        { icon: "bookmark", text: "Bookmark", blurb: "save papers for later review." },
                        { icon: "circle-plus", text: "Add to Library", blurb: "build your personal collection." },
                        { icon: "file-text", text: "Quick View", blurb: "preview abstracts and relevance information." },
                        { icon: "message-lines", text: "Summarize", blurb: "get a summary and explanation of the paper." }
                    ]}
                />
            </div>
        </div>
        </div>
    );
};

export default LandingResultsExplainer;
