import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';


import Typewriter from 'typewriter-effect';
import Icon from './Icon';

const StatusUpdates = ({ icon, text, color, isVisible }) => {
    const [showAnimation, setShowAnimation] = useState(false);

    useEffect(() => {
        const timer = setTimeout(() => setShowAnimation(true), 1000);
        return () => clearTimeout(timer);
    }, [text]);


    return (
        isVisible && (
        <motion.div
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: 'auto' }}
            exit={{ opacity: 0, height: 0 }}
            transition={{ duration: 0.3 }}
            className="px-8 py-2 select-none w-4/5 mx-auto"
        >
            <div className="flex items-center">
                <span className={`mr-2 ${color}`}><Icon icon={icon} /></span>
                <span className={color}>
                    <Typewriter
                        options={{
                            strings: Array.isArray(text) ? text : [text],
                            autoStart: true,
                            loop: true,
                            delay: 30,
                        }}
                    />
                </span>
            </div>
            {showAnimation && (
                <motion.div
                    className="mt-2 h-1 bg-gray-200 rounded-full overflow-hidden"
                    initial={{ width: 0 }}
                    animate={{ width: '100%' }}
                    transition={{ duration: 5, repeat: Infinity }}
                >
                    <motion.div
                        className={`h-full ${color}`}
                        initial={{ x: '-100%' }}
                        animate={{ x: '100%' }}
                        transition={{ duration: 1, repeat: Infinity, ease: 'linear' }}
                    />
                </motion.div>
            )}
        </motion.div>
        )
    );
};

export default StatusUpdates;
