import React, { useState, useEffect, useRef, useMemo, useCallback } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { toast } from 'react-toastify';
import posthog from 'posthog-js';

import Icon from '../elements/Icon';
import TopicsMenu from '../elements/TopicsMenu';
import PaperDetails, { PaperDetailsPlaceholder } from './PaperDetails';
import Modal from '../elements/Modal';
import sendPostRequest from '../scripts/Requests';
import { SmartSortModal } from './SmartSort';
import PaperListItem from './PaperListItem';
import { useUserContext } from '../UserContext';
import PapersGraph from '../elements/PapersGraph';
import PaginatedList from '../elements/Pagination';
import { TooltipButton } from '../elements/Buttons';

const apiUrl = process.env.REACT_APP_API_URL;


export function generatePastelColor() {
    const hue = Math.floor(Math.random() * 360);
    return `hsl(${hue}, 70%, 90%)`;
  }


const PapersDisplay = ({ 
      papers = [], 
      references = [],
      setPapers, 
      isLoading, 
      isActive=false,
      showSmartSort=true, 
      showTopicsMenu=true, 
      listItemParams={}, 
      paperDetailsParams={},
      setSearchQuery = null,
      showGraphs=false
  }) => {
  const { user_id, isFreeTier } = useUserContext();
  const [selectedPaper, setSelectedPaper] = useState(null);
  const [sortBy, setSortBy] = useState('relevance');
  const [sortOrder, setSortOrder] = useState('desc');
  const [selectedTopic, setSelectedTopic] = useState(null);
  const [selectedKeyword, setSelectedKeyword] = useState(null);
  const [isMobileModalOpen, setIsMobileModalOpen] = useState(false);
  const [isSmartSortModalOpen, setIsSmartSortModalOpen] = useState(false);
  const [smartSortPrompt, setSmartSortPrompt] = useState('');
  const [selectedLibraries, setSelectedLibraries] = useState([]);
  const [isNetworkVisible, setIsNetworkVisible] = useState(true);
  const [secondLevelFilterRanges, setSecondLevelFilterRanges] = useState({
    n_citations: null,
    year: null,
    score: null,
  });

  const paperDetailsRef = useRef(null);
  const paperListRef = useRef(null);

  const heightParams = "h-[140vh] max-h-[1400px]"
  const mobileHeightParams = "h-[200vh] max-h-[4000px]"

  const [isMobile, setIsMobile] = useState(window.innerWidth < 1024);

  useEffect(() => {
    if (isLoading) {
      setIsNetworkVisible(false);
      setSecondLevelFilterRanges({
        n_citations: null,
        year: null,
        score: null,
      });
    }
  }, [isLoading]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1024);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (papers.length > 0) {
      setSelectedPaper(papers[papers.length - 1]);
    }
    if (papers.length === 0) {
      setSelectedPaper(null);
    }
  }, [papers]);

  useEffect(() => {
    if (paperDetailsRef.current) {
      paperDetailsRef.current.scrollTop = 0;
    }
  }, [selectedPaper]);

  const sortedPapers = React.useMemo(() => {
    let sorted = [...papers];
    if (sortBy === 'year') {
      sorted.sort((a, b) => a.year - b.year);
    } else if (sortBy === 'citations') {
      sorted.sort((a, b) => a.n_citations - b.n_citations);
    } else if (sortBy === 'relevance') {
      const papersWithScore = sorted.filter(paper => paper.score != null);
      if (papersWithScore.length > sorted.length / 2) {
        sorted.sort((a, b) => (a.score || 0) - (b.score || 0));
      }
      // If not enough papers have scores, keep the default order
    }

    return sortOrder === 'asc' ? sorted : sorted.reverse();
  }, [papers, sortBy, sortOrder]);

  const filteredPapers = React.useMemo(() => {
    return sortedPapers.filter(paper => {
      const topicMatch = !selectedTopic || (paper.fieldsOfStudy && paper.fieldsOfStudy.includes(selectedTopic));
      const keywordMatch = !selectedKeyword || (paper.keywords && paper.keywords.includes(selectedKeyword));
      return topicMatch && keywordMatch;
    });
  }, [sortedPapers, selectedTopic, selectedKeyword]);

  const secondLevelFilteredPapers = React.useMemo(() => {
    return filteredPapers.filter(paper => {
      const citationMatch = !secondLevelFilterRanges.n_citations || 
        (paper.n_citations >= secondLevelFilterRanges.n_citations[0] && 
         paper.n_citations <= secondLevelFilterRanges.n_citations[1]);
      const yearMatch = !secondLevelFilterRanges.year || 
        (paper.year >= secondLevelFilterRanges.year[0] && 
         paper.year <= secondLevelFilterRanges.year[1]);
      const scoreMatch = !secondLevelFilterRanges.score || 
        (paper.score * 100 >= secondLevelFilterRanges.score[0] && 
         paper.score * 100 <= secondLevelFilterRanges.score[1]);
      return citationMatch && yearMatch && scoreMatch;
    });
  }, [filteredPapers, secondLevelFilterRanges]);

  const topicCounts = React.useMemo(() => {
    const counts = {};
    papers.forEach(paper => {
      if (paper.fieldsOfStudy && Array.isArray(paper.fieldsOfStudy)) {
        paper.fieldsOfStudy.forEach(topic => {
          counts[topic] = (counts[topic] || 0) + 1;
        });
      }
    });
    return Object.entries(counts).sort((a, b) => b[1] - a[1]);
  }, [papers]);

  const keywordCounts = React.useMemo(() => {
    const counts = {};
    papers.forEach(paper => {
      (paper.keywords || []).forEach(keyword => {
        counts[keyword] = (counts[keyword] || 0) + 1;
      });
    });
    return Object.entries(counts).sort((a, b) => b[1] - a[1]);
  }, [papers]);

  const topicColors = React.useMemo(() => {
    const colors = {};
    topicCounts.forEach(([topic]) => {
      colors[topic] = generatePastelColor();
    });
    return colors;
  }, [topicCounts]);


  const handleSmartSort = () => {
    if (isLoading) return;
    setIsSmartSortModalOpen(true);
    posthog.capture('Smart Sort Opened', { paperCount: papers.length });
  }

  const handleSmartSortSubmit = async () => {
    try {
      posthog.capture('Smart Sort Submitted', { prompt: smartSortPrompt });
      setSearchQuery(smartSortPrompt);
      const [response, success] = await sendPostRequest('/backend/smart-sort', {
        papers: papers,
        prompt: smartSortPrompt,
        user_id: user_id,
        is_free_tier: isFreeTier
      });

      if (success) {
        const updatedPapers = response.papers;
        // Update the papers state with the new scores
        setPapers(updatedPapers);
        // Re-apply the sort by relevance
        setSortBy('relevance');
        setIsSmartSortModalOpen(false);
        posthog.capture('Smart Sort Completed', { paperCount: updatedPapers.length });
      } else {
        console.error('Failed to perform SmartSort');
        toast.error('Failed to perform SmartSort, please try again.');
        posthog.capture('Smart Sort Failed', { error: 'API Error' });
      }
    } catch (error) {
      console.error('Error during SmartSort:', error);
      toast.error('Failed to perform SmartSort, please try again.');
      posthog.capture('Smart Sort Failed', { error: error.message });
    }
  }


  const handleOpenDetails = (paper) => {
    setSelectedPaper(paper);
    if (isMobile) {
      setIsMobileModalOpen(true);
    }
    posthog.capture('Paper Details Opened', { paperId: paper.paperId, title: paper.title });
  };


  const listWidthDesktop = papers.length === 0 ? "70%" : (
    showTopicsMenu ? "50%" : "60%"
  )

  const memoizedGraphData = useMemo(() => ({
    recommendations: secondLevelFilteredPapers,
    references: references,
  }), [secondLevelFilteredPapers, references]);

  const scrollToSelectedPaper = useCallback(() => {
    if (paperListRef.current && selectedPaper) {
      const paperElement = paperListRef.current.querySelector(`[data-paper-id="${selectedPaper.paperId}"]`);
      if (paperElement) {
        paperElement.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
      }
    }
  }, [selectedPaper]);

  const renderPaperItem = (paper, index) => (
    <PaperListItem
      key={`${paper.paperId}-${index}`}
      paper={paper}
      onClick={() => handleOpenDetails(paper)}
      isSelected={paper.paperId === selectedPaper?.paperId}
      isLoading={isLoading}
      onOpenDetails={() => handleOpenDetails(paper)}
      data-paper-id={paper.paperId}  // Add this attribute
      {...listItemParams}
    />
  );

  const handleSecondLevelFilter = (ranges) => {
    setSecondLevelFilterRanges(ranges);
  };

  const handleTopicOrKeywordChange = (newTopic, newKeyword) => {
    setSelectedTopic(newTopic);
    setSelectedKeyword(newKeyword);
    // Reset second level filter ranges
    setSecondLevelFilterRanges({
      n_citations: null,
      year: null,
      score: null,
    });
  };

  const renderPapersGraph = ({onMobile=false}) => {
    const mobileToggle = onMobile ? isMobile : !isMobile;

    if (showGraphs && isNetworkVisible && mobileToggle) {
      
      return (
        <AnimatePresence mode="wait">
          <PapersGraph {...memoizedGraphData} selectedPaper={selectedPaper} setSelectedPaper={setSelectedPaper} isActive={isActive} key={`graph-${onMobile}`}/>
        </AnimatePresence>
      )
    }
  }

  const handleExport = async () => {
    try {
      const response = await fetch(`${apiUrl}/backend/export-results`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-API-Environment': process.env.REACT_APP_API_ENVIRONMENT || 'development',
        },
        body: JSON.stringify({
          paper_ids: secondLevelFilteredPapers.map(paper => paper.paperId),
          scores: secondLevelFilteredPapers.map(paper => paper.score),
        }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      a.download = 'search_results.csv';
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      toast.success("Papers exported successfully");
    } catch (error) {
      console.error("Error exporting papers:", error);
      toast.error("An error occurred while exporting papers");
    }
  };

  return (
    <motion.div
      animate={(isLoading && papers.length > 0) ? { opacity: [0.3, 1, 0.3] } : { opacity: 1 }}
      transition={(isLoading && papers.length > 0) ? { duration: 1.5, repeat: Infinity, ease: "easeInOut" } : {}}
      ref={paperListRef}  // Add this ref
    >
      <div className="w-full max-w-7xl mx-auto mt-5">



        <div className="flex flex-col lg:flex-row gap-8">
          {/* Topics Menu */}
          {showTopicsMenu && (
          <motion.div 
            className={`order-2 lg:w-[15%] ${isMobile ? "mx-auto -mb-4" : ""}`}
            initial={{ width: papers.length === 0 ? "0%" : isMobile ? "90%" : "15%" }}
            animate={{ width: papers.length === 0 ? "0%" : isMobile ? "90%" : "15%" }}
            transition={{ duration: 0.3 }}
            
          >
            <TopicsMenu
              showGraphs={showGraphs}
              topicCounts={topicCounts}
              keywordCounts={keywordCounts}
              selectedTopic={selectedTopic}
              setSelectedTopic={(newTopic) => handleTopicOrKeywordChange(newTopic, selectedKeyword)}
              selectedKeyword={selectedKeyword}
              setSelectedKeyword={(newKeyword) => handleTopicOrKeywordChange(selectedTopic, newKeyword)}
              topicColors={topicColors}
              height={isMobile ? mobileHeightParams : heightParams}
              onSmartSortButtonClick={showSmartSort ? handleSmartSort : null}
              filteredPapers={filteredPapers}
              onSecondLevelFilter={handleSecondLevelFilter}

            />

          </motion.div>
          )}


          

          { /* ------------------------------- Papers List ------------------------------  */}
          <motion.div
            className={`
                        order-2
                        
                        ${isMobile ? mobileHeightParams : heightParams} 
                        flex flex-col
                        ${isMobile ? "mx-auto" : ""}
                    `}
            initial={{ width: isMobile ? "90%" : listWidthDesktop}}
            animate={{ width: isMobile ? "90%" : listWidthDesktop}}
            transition={{ duration: 0.3 }}
          >
            {secondLevelFilteredPapers.length === 0  ? (
              <PlaceholdersList isLoading={isLoading} />
            ) : (
                <>

                  {/* graph on mobile */}
                  {renderPapersGraph({onMobile: true})}

                  <SortingMenu
                      sortBy={sortBy}
                      setSortBy={setSortBy}
                      sortOrder={sortOrder}
                      setSortOrder={setSortOrder}
                      totalPapers={secondLevelFilteredPapers.length}
                      isLoading={isLoading}
                      isNetworkVisible={isNetworkVisible}
                      setIsNetworkVisible={setIsNetworkVisible}
                      onExport={handleExport}
                  />

            
                  {/* papers */}
                  <PaginatedList
                    items={isLoading ? secondLevelFilteredPapers.slice(0, 8) : secondLevelFilteredPapers}
                    renderItem={renderPaperItem}
                    itemsPerPage={25}
                  />
              </>
            )}
          </motion.div>

          {/* Paper Details */}
          <div className={`hidden lg:block order-3 w-[35%] ${isMobile ? mobileHeightParams : heightParams} `}>
            {renderPapersGraph({onMobile: false})}
              <div div ref={paperDetailsRef} >
            <AnimatePresence mode="wait">
              {selectedPaper ? (
                <PaperDetails 
                  key={`details-${selectedPaper.paperId}`} 
                  paper={selectedPaper} 
                  isLibraryPaper={references.some(ref => ref.paperId === selectedPaper.paperId)}
                  selectedLibraries={selectedLibraries}
                  setSelectedLibraries={setSelectedLibraries}
                  {...paperDetailsParams}
                />
              ) : (
                <PaperDetailsPlaceholder key="placeholder" isLoading={isLoading} />
              )}
            </AnimatePresence>
            </div>
          </div>
        </div>
      </div>

      {/* Mobile Modal for Paper Details */}
      <Modal isOpen={isMobileModalOpen} onClose={() => setIsMobileModalOpen(false)}>
        {selectedPaper && (
          <PaperDetails 
            key={`details-${selectedPaper.paperId}`}
            paper={selectedPaper} 
            isMobile={true}
            {...paperDetailsParams}
          />
        )}
      </Modal>


      <SmartSortModal
        isOpen={isSmartSortModalOpen}
        onClose={() => setIsSmartSortModalOpen(false)}
        smartSortPrompt={smartSortPrompt}
        setSmartSortPrompt={setSmartSortPrompt}
        handleSmartSortSubmit={handleSmartSortSubmit}
        setSearchQuery={setSearchQuery}
      />
    </motion.div>

  );
};


/* -------------------------------------------------------------------------- */
/*                                    MENUS                                   */
/* -------------------------------------------------------------------------- */

/* --------------------------------- SORTING -------------------------------- */

const SortingMenu = ({ sortBy, setSortBy, sortOrder, setSortOrder, totalPapers, isLoading, isNetworkVisible, setIsNetworkVisible, onExport }) => {
  const handleSortByChange = (option) => {
    setSortBy(option);
    posthog.capture('Sort By Changed', { sortBy: option });
  };

  const handleSortOrderChange = (order) => {
    setSortOrder(order);
    posthog.capture('Sort Order Changed', { sortOrder: order });
  };

  return (
    <div className={`flex flex-row w-full h-fit ${isLoading ? 'opacity-50' : 'opacity-100'} transition-opacity duration-300 h-[9999]`}>
      <div className="flex lg:flex-row lg:gap-0 gap-4  justify-between items-center mb-2 w-full">
        {/* Paper Count */}
        <div className={`text-sm text-gray-500 ${isLoading ? 'opacity-0' : 'opacity-100'} transition-opacity duration-300`}>
          <span className="font-semibold">{totalPapers}</span> papers
        </div>

        {/* actions */}
        <div className="flex items-center">
          {/* Export button */}
          <TooltipButton
            icon="file-export"
            onClick={onExport}
            tooltip="Export papers to CSV"
            className="papers-display-controls-button px-1 border-b-2 w-fit"
          />

          {/* show network graph */}
          <TooltipButton
            icon="chart-network"
            onClick={() => setIsNetworkVisible(!isNetworkVisible)}
            tooltip="Toggle network graph"
            className={`
              papers-display-controls-button px-2 
              border-b-2 w-fit mr-2
              ${isNetworkVisible ? 'papers-display-controls-button-active' : ''}
            `}
          />
    
          {/* Sort Type */}
          <div className="flex border-x border-gray-300 px-2">
            {[
              ['relevance', 'fire', 'Sort by relevance'],
              ['year', 'calendar', 'Sort by year'],
              ['citations', 'chart-line', 'Sort by citations'] 
            ].map(([option, icon, tooltipText]) => (
              <TooltipButton
                key={option}
                icon={icon}
                onClick={() => handleSortByChange(option)}
                tooltip={tooltipText}
                className={`
                  papers-display-controls-button px-2 
                  border-b-2 w-fit
                  ${sortBy === option ? 'papers-display-controls-button-active' : ''}
                `}
              />
            ))}
          </div>

          {/* Sort Order */}
          <div className="flex">
            <TooltipButton
              icon="sort-amount-up"
              onClick={() => handleSortOrderChange('asc')}
              tooltip="Sort ascending"
              className={`papers-display-controls-button px-2 border-b-2  
                ${sortOrder === 'asc' ? 'papers-display-controls-button-active' : ''}`}
            />
            <TooltipButton
              icon="sort-amount-down"
              onClick={() => handleSortOrderChange('desc')}
              tooltip="Sort descending"
              className={`papers-display-controls-button px-2 border-b-2  
                ${sortOrder === 'desc' ? 'papers-display-controls-button-active' : ''}`}
            />
          </div>
        </div>
      </div>
    </div>
  );
};




/* -------------------------------------------------------------------------- */
/*                             PAPER LIST EMELENTS                            */
/* -------------------------------------------------------------------------- */

const PlaceholdersList = ({ isLoading }) => {
    const [activeIndex, setActiveIndex] = useState(0);
  
    useEffect(() => {
      let interval;
      if (isLoading) {
        interval = setInterval(() => {
          setActiveIndex((prevIndex) => (prevIndex + 1) % 5);
        }, 500);
      }
  
      return () => {
        if (interval) clearInterval(interval);
      };
    }, [isLoading]);
  
    return (
      <div className="flex flex-col gap-2 w-full">
        {Array.from({ length: 10 }).map((_, index) => (
          <motion.div
            key={index}
            animate={{
              scale: isLoading && index === activeIndex ? 0.96 : 1,
            }}
            transition={{
              duration: 0.5,
              ease: "easeInOut",
            }}
          >
            <PaperPlaceholder />
          </motion.div>
        ))}
      </ div>
    );
  };





const PaperPlaceholder = () => (
    <div className="bg-white border border-gray-200">
      <div className="flex p-4">
        <div className="w-1/5 flex flex-col items-center justify-center">
          <div className="w-12 h-6 bg-gray-300 rounded mb-1"></div>
          <div className="w-12 h-4 mt-1 bg-gray-200 rounded mb-1"></div>
        </div>
        <div className="w-4/5">
          <div className="w-4/5 h-6 bg-gray-300 rounded mb-2"></div>
          <div className="w-1/2 ml-2 h-4 bg-gray-200 rounded"></div>
        </div>
        </div>
      </div>
    );




export default PapersDisplay;
export { PlaceholdersList }







