import { useEffect } from "react";
import * as Sentry from "@sentry/react";
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router-dom";

Sentry.init({
  dsn: "https://a700a054a163378f2a0d9d1ae4ac84b9@o4507987208634368.ingest.us.sentry.io/4507987212369920",
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
    Sentry.replayIntegration(),
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for tracing.
  tracesSampleRate: 1.0,

  // Set `tracePropagationTargets` to control for which URLs trace propagation should be enabled
  tracePropagationTargets: [
    /^\//, /^https:\/\/litsearch\.io\/api/, /^https:\/\/litsearch\.io\//,
     /^https:\/\/try.litsearch\.io\//,  /^https:\/\/get.litsearch\.io\//
    
  ],

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,

  beforeSend(event) {
    // Check if the error is from localhost
    if (window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1') {
      return null;  // Ignore events from localhost
    }
    return event;  // Send the event to Sentry
  }
});
