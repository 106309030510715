import io from 'socket.io-client';
import posthog from 'posthog-js';
import * as Sentry from '@sentry/react';

const socketUrl = process.env.REACT_APP_SOCKET_URL;

const socket = io(socketUrl, {  
  withCredentials: true,
  transports: ['websocket'],
  path: '/socket.io',  // Make sure this matches your server configuration
  timeout: 10000, // Increase timeout to 10 seconds
  reconnection: true,
  reconnectionAttempts: 5,
  reconnectionDelay: 1000,
});

socket.on('connect', () => {
  console.log('Socket connected at', socketUrl);
});

socket.on('connect_error', (error) => {
  console.error('Socket connection error:', error);
  console.error('Error details:', error.message, error.description);
});

socket.on('disconnect', (reason) => {
  console.log('Socket disconnected:', reason);
});

socket.io.on("error", (error) => {
  console.error('Transport error:', error);
});

socket.io.on("reconnect_attempt", (attempt) => {
  console.log('Reconnection attempt:', attempt);
});

// Add more event listeners for debugging
socket.on('reconnect_attempt', (attemptNumber) => {
  console.log('Attempting reconnection:', attemptNumber);
});

socket.on('reconnect_error', (error) => {
  console.error('Reconnection error:', error);
});

socket.on('reconnect_failed', () => {
  console.error('Failed to reconnect');
});

// Add a new event listener for generic errors
socket.on('error', (error) => {
  console.error('Socket generic error:', error);
  
  // Log to PostHog
  posthog.capture('socket_error', {
    error_message: error.message || 'Unknown socket error',
    error_details: error
  });

  // Log to Sentry
  Sentry.captureException(new Error('Socket Error: ' + (error.message || 'Unknown socket error')), {
    extra: {
      error_details: error
    }
  });
});

export default socket;