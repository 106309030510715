import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { toast } from 'react-toastify';

import FeaturesCard, { features, stripePromise } from './FeaturesCard';
import sendPostRequest from '../scripts/Requests';
import Icon from '../elements/Icon';


const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1
      }
    }
  };
  
  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.5
      }
    }
  };

const SubscriptionStatusContainer = ({ status, features, trialEnd, prices, color, user }) => {
  if (status === 'active') {
    return <ActiveSubscription color={color} />;
  } else if (status === 'inactive' || status === 'canceled') {
    return <InactiveSubscription color={color} features={features} prices={prices} user={user} />;
  } else if (status === 'trialing') {
    return <TrialSubscription color={color} trialEnd={trialEnd} />;
  } else if (status === 'free' || status === 'no_subscription') {
    return <NotSubscribed features={features} user={user} prices={prices} />;
  }
  return null;
};


const InfoText = ({icon, children}) => {
  return (
    <div className="flex flex-row gap-1 items-start my-3">
      <Icon icon={icon} className="text-gray-500 pt-0.5" />
      <p className="text-sm">{children}</p>
    </div>
  );
}

const ActiveSubscription = ({ color }) => {
  return (
    <motion.div 
      className={`w-full overflow-visible ${color} relative h-[85%]`}
      variants={containerVariants}
      initial="hidden"
      animate="visible"
    >
      <div className="flex flex-row gap-4 items-start w-full mt-4 h-full">
        <div className="flex flex-col w-full overflow-visible">
          <motion.h3 className="text-xl font-semibold text-gray-600" variants={itemVariants}>
            You're a LitSearch Pro subscriber!
          </motion.h3>
          <motion.p className="mb-2 text-sm" variants={itemVariants}>
            <Icon icon="check-circle" className="text-green-500 mr-1" />Active subscription
          </motion.p>

          <p className="text-sm my-4">
            Thank you for supporting LitSearch! We hope you're enjoying all the awesome features of your Pro account.<br/>
            You have access to all LitSearch Pro features.<br/>
            You can manage or cancel your subscription at any time.
          </p>

          <motion.div variants={itemVariants}>
            <button
              onClick={() => window.open("https://billing.stripe.com/p/login/test_9AQbMffGj7E31Ta4gg", '_blank')}
              className="mt-2 py-0.5 px-4 rounded border border-subscription hover:bg-subscription text-subscription hover:text-white transition-all duration-150 group"
            >
              <Icon icon="credit-card" className="mr-1 " />
              Manage Your Subscription
            </button>
          </motion.div>
        </div>
      </div>

      <div className="absolute bottom-0 left-0 right-0">
        <InfoText icon="triangle-exclamation">
          If you cancel your subscription you will lose access to LitSearch Pro features.
        </InfoText>
      </div>
    </motion.div>
  );
};



const TrialSubscription = ({ color, trialEnd }) => {
    const trialEndDate = new Date(trialEnd * 1000).toLocaleDateString();
    return (
      <motion.div 
        className={`w-full overflow-visible ${color} relative h-[85%]`}
        variants={containerVariants}
        initial="hidden"
        animate="visible"
      >
        <div className="flex flex-row gap-4 items-start w-full mt-4 h-full">
          <div className="flex flex-col w-full overflow-visible">
            <motion.h3 className="text-xl font-semibold text-gray-600" variants={itemVariants}>
              You're on a LitSearch Pro trial!
            </motion.h3>
            <motion.p className="mb-2 text-sm" variants={itemVariants}>
            <Icon icon="calendar" className="text-gray-500 mr-1" />valid until: {trialEndDate}
            </motion.p>

            <p className="text-sm my-4">
            While on trial, you will have access to all of LitSearch Pro features.<br/>
            Your subscription will automatically continue after the trial period.<br/>
            You can cancel your subscription anytime.
            </p>

            <motion.div variants={itemVariants}>
              <button
                onClick={() => window.open("https://billing.stripe.com/p/login/test_9AQbMffGj7E31Ta4gg", '_blank')}
                className="mt-2 py-0.5 px-4 rounded border border-subscription hover:bg-subscription text-subscription hover:text-white transition-all duration-150 group"
              >
                <Icon icon="credit-card" className="mr-1 " />
                Manage Your Subscription
              </button>
            </motion.div>
          </div>
        </div>

        <div className="absolute bottom-0 left-0 right-0">

          <InfoText icon="triangle-exclamation">
            If you cancel your subscription you will lose access to LitSearch Pro features.
          </InfoText>
        </div>
      </motion.div>
    );
  };

const InactiveSubscription = ({ color, features, prices, user }) => {

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    
    const handleSubscribe = async (priceId) => {
      setLoading(true);
      setError(null);
    
      try {
        const [response, success] = await sendPostRequest(`/backend/create-checkout-session`, 
          {
            priceId: priceId,
            userId: user.id,
          }
        );
    
        if (!success) {
          setError(response.error);
          toast.error("Something went wrong. Please try again.");
          return;
        }
    
        const stripe = await stripePromise;
        const { error } = await stripe.redirectToCheckout({ sessionId: response.sessionId });
    
        if (error) {
          setError(error.message);      
          toast.error("Something went wrong. Please try again.");
        }
      } catch (err) {
        setError('An error occurred. Please try again.');
      } finally {
        setLoading(false);
      }
    };
    
  

  return (
    <motion.div 
      className={`overflow-scroll`}
      variants={containerVariants}
      initial="hidden"
      animate="visible"
    >
      <motion.h3 className="text-lg text-red-600" variants={itemVariants}>
        <Icon icon="triangle-exclamation" className="text-accent mr-2" style="solid" />
        Your Subscription Has Expired
      </motion.h3>
      <motion.p className="mb-4" variants={itemVariants}>
        <span className="highlight-border">Renew your subscription</span> to regain access to all LitSearch Pro features.
      </motion.p>

      <motion.div className="my-8" variants={itemVariants}>
        <FeaturesCard 
          features={features}
          onSubscribe={handleSubscribe}
          loading={loading}
          prices={prices}
        />
      </motion.div>
    </motion.div>
  );
};


const NotSubscribed = ({features, user, prices }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  
  const handleSubscribe = async (priceId) => {
    setLoading(true);
    setError(null);
  
    try {
      const [response, success] = await sendPostRequest(`/backend/create-checkout-session`, 
        {
          priceId: priceId,
          userId: user.id,
        }
      );
  
      if (!success) {
        setError(response.error);
        toast.error("Something went wrong. Please try again.");
        return;
      }
  
      const stripe = await stripePromise;
      const { error } = await stripe.redirectToCheckout({ sessionId: response.sessionId });
  
      if (error) {
        setError(error.message);      
        toast.error("Something went wrong. Please try again.");
      }
    } catch (err) {
      setError('An error occurred. Please try again.');
    } finally {
      setLoading(false);
    }
  };
  

  
  return (
    <motion.div 
      className="text-left"
      variants={containerVariants}
      initial="hidden"
      animate="visible"
    >
      <motion.h3 className="text-xl font-semibold mb-1" variants={itemVariants}>
        <Icon icon="fire" className="text-subscription mr-1" style="light" />
        Upgrade to LitSearch Pro
      </motion.h3>
      <motion.p className="mb-1" variants={itemVariants}>
        You currently have a Free account.<br/>
        Unlock all features and <span className="highlight-border">supercharge your research</span> with a Pro subscription!
      </motion.p>
      
      <motion.div className="mt-4 pt-4 border-t" 
        variants={itemVariants}
      >
        <FeaturesCard 
          features={features}
          onSubscribe={handleSubscribe}
          loading={loading}
          prices={prices}
        />
      </motion.div>
       
      {error && <motion.p className="text-red-500 mb-4" variants={itemVariants}>{error}</motion.p>}
    </motion.div>
  );
};

export default SubscriptionStatusContainer;
