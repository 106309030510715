import React, { createContext, useState, useContext, useEffect } from 'react';
import { useUser } from '@clerk/clerk-react';
import { checkSubscriptionStatus } from './scripts/CheckSubscription';
import posthog from 'posthog-js';

import fetchLibraries from './scripts/FetchUserLibraries';
import sendPostRequest, { sendGetRequest } from './scripts/Requests';

const UserContext = createContext();


const tierMap = {
  'active': 'pro',
  'trialing': 'pro',
  'past_due': 'free',
  'canceled': 'free',
  'incomplete': 'free',
  'incomplete_expired': 'free',
  'trialing': 'pro',
}

export const UserProvider = ({ children }) => {
  const { user } = useUser();
  const [userId, setUserId] = useState(null);
  const [subscriptionTier, setSubscriptionTier] = useState('free');
  const [subscriptionStatus, setSubscriptionStatus] = useState('loading');
  const [trialEnd, setTrialEnd] = useState(null);
  const [customerId, setCustomerId] = useState(null);
  const [isFreeTier, setIsFreeTier] = useState(false);
  const [userLoaded, setUserLoaded] = useState(false);
  const [libraries, setLibraries] = useState([]);
  const [bookmarks, setBookmarks] = useState([]);
  const [userPhoto, setUserPhoto] = useState(null);
  const [userName, setUserName] = useState(null);
  const [userEmail, setUserEmail] = useState(null);
  const [accountCreated, setAccountCreated] = useState(null);

  useEffect(() => {
    if (user) {
      
      // // Identify user in PostHog
      // posthog.identify(user.id, {
      //   email: user.primaryEmailAddress.emailAddress,
      //   name: user.fullName,
      // });
      
      const checkSubscription = async () => {
        try {
          const customer_info = await checkSubscriptionStatus(user);
          const status = customer_info.status;

          console.log(customer_info, status);

          setCustomerId(customer_info.customerId);
          setSubscriptionStatus(status);
          setTrialEnd(customer_info.trialEnd);
          setSubscriptionTier(tierMap[status]);
          setIsFreeTier(!['active', 'trialing', ].includes(status));

          // Set additional user info
          setUserId(user.id);
          setUserPhoto(user.imageUrl);
          setUserName(user.fullName);
          setUserEmail(user.primaryEmailAddress.emailAddress);
          setAccountCreated(user.createdAt);
          console.log(user.profileImageUrl);
          console.log(user)

          setUserLoaded(true);

          // Fetch libraries after setting user info
          fetchLibraries(user.id, tierMap[status], setLibraries, 'UserContext');

          // Fetch bookmarks after setting user info
          fetchBookmarks(user.id);
        } catch (error) {
          setSubscriptionStatus('error');
        }
      };

      checkSubscription();
    }
  }, [user]);

  const fetchBookmarks = async (userId) => {
    const [data, success, error] = await sendGetRequest('/backend/bookmarks', 'Get Bookmarks', { user_id: userId });
    if (success) {
      setBookmarks(data);
    } else {
      console.error('Error fetching bookmarks:', error);
    }
  };

  const toggleBookmark = async (paper) => {
    const [response, success, error] = await sendPostRequest('/backend/bookmark', { user_id: userId, paper_id: paper.paperId }, 'Toggle Bookmark');
    if (success) {
      // Fetch updated bookmarks after toggling
      await fetchBookmarks(userId);
    } else {
      console.error('Error toggling bookmark:', error);
    }
  };



  return (
    <UserContext.Provider value={{
      user,
      userId,
      subscriptionTier,
      subscriptionStatus,
      trialEnd,
      customerId,
      isFreeTier,
      userLoaded,
      libraries,
      setLibraries,
      bookmarks,
      toggleBookmark,
      fetchBookmarks,
      userPhoto,
      userName,
      userEmail,
      accountCreated
    }}>
      {children}
    </UserContext.Provider>
  );
};

export const useUserContext = () => useContext(UserContext);
