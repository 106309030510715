import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { motion } from 'framer-motion';
import posthog from 'posthog-js';

import SearchSettings from '../components/SearchSettings';
import ViewContainer from '../elements/ViewContainer';
import SelectLibrary from '../elements/SelectLibrary';
import PapersDisplay from '../components/PapersDisplay';
import sendPostRequest, { sendGetRequest } from '../scripts/Requests';
import socket from '../scripts/socket';
import StatusUpdates from '../elements/StatusUpdates';
import { useUserContext } from '../UserContext';


const LibrarySearchView = ({ setActiveView, isActive}) => {
    const { userId, subscriptionTier } = useUserContext();
    const [selectedLibrary, setSelectedLibrary] = useState(null);
    const [papers, setPapers] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [startYear, setStartYear] = useState('');
    const [endYear, setEndYear] = useState('');
    const [numResults, setNumResults] = useState('');
    const [showSettings, setShowSettings] = useState(false);
    const [statusUpdates, setStatusUpdates] = useState({});
    const [searchQuery, setSearchQuery] = useState(null);
    const [libraryReferences, setLibraryReferences] = useState([]);
    const [showGraphs, setShowGraphs] = useState(false);

    
    const updateStatus = (icon, text, color='text-gray-400') => {
        setStatusUpdates({
            icon: icon,
            text: text,
            color: color
        });
      };

    // socket events
    useEffect(() => {
        socket.on('library_search_started', (data) => {
            setIsLoading(true);
            updateStatus('robot', [
                'Looking up papers candidates..',
                'Finding the most relevant papers..',
                'Looking for your next read...'
            ], );
            setShowGraphs(false);
        });

        socket.on('library_search_error', (data) => {
            updateStatus('triangle-exclamation', 'Search error', 'text-accent');
            setIsLoading(false);
            setShowGraphs(false);
        });

        socket.on('library_search_results', (data) => {
            setPapers(data.papers);
        });

        socket.on('library_search_queries_completed', (data) => {
            updateStatus('robot', [
                'Processing results...',
                "Making sure we're selecting the very best papers...",
                "Processing results...",
                "Almost done..."
            ], );
            setPapers(data.papers);
            setShowGraphs(false);
        });
        socket.on('library_search_processing_completed', (data) => {
            updateStatus('sort', 'Finding the most relevant papers..', );
            setPapers(data.papers);
            
        });
        socket.on('library_search_completed', (data) => {
            updateStatus('check', 'Search completed', 'text-secondary' );
            setIsLoading(false);
            setPapers(data.papers);
            setShowGraphs(true);
        });

        socket.on('library_search_usage_error', (data) => {
            toast.error('Free tier users can only perform a few library searches per week. Please upgrade to LitSearch Pro to continue.');
            setIsLoading(false);
            updateStatus('triangle-exclamation', 'You have reached your weekly limit of library searches.', 'text-accent');
            setShowGraphs(false);
        });

        return () => {
            socket.off('library_search_started');
            socket.off('library_search_error');
            socket.off('library_search_results');
            socket.off('library_search_queries_completed');
            socket.off('library_search_processing_completed');
            socket.off('library_search_completed');
        };
    }, []);


    const handleSelectLibrary = async (library) => {
        setSelectedLibrary(library);
        setShowSettings(true);
        setShowGraphs(false);
        setPapers([]);
        
        // Fetch library references
        try {
            const [references, success] = await sendGetRequest(
                `/backend/libraries/${library.id}/papers`,
                'get-library-references'
            );
            if (success) {
                setLibraryReferences(references);
            } else {
                toast.error("Failed to fetch library references");
            }
        } catch (error) {
            console.error("Error fetching library references:", error);
            toast.error("Failed to fetch library references");
        }
    };

    const handleStartSearch = async () => {
        setPapers([]);
        setStatusUpdates({});
        setIsLoading(true);
        posthog.capture('library_search_started', {
            library_id: selectedLibrary.id,
            start_year: parseInt(startYear) || 1900,
            end_year: parseInt(endYear) || new Date().getFullYear(),
            num_results: Math.min(parseInt(numResults) || 200, 1000),
        });

        try {
            // get search params
            const payload = {
                library_id: selectedLibrary.id,
                description: selectedLibrary.description,
                start_year: parseInt(startYear) || 1900,
                end_year: parseInt(endYear) || new Date().getFullYear(),
                limit: Math.min(parseInt(numResults) || 200, 1000),
                user_id: userId,
                is_free_tier: subscriptionTier === 'free'
            };
            setSearchQuery(selectedLibrary.description);

            // send request to backend
            updateStatus('robot', 'Starting search...', );
            const [response, searchSuccess] = await sendPostRequest('/backend/library-search', payload, 'library-search');
            if (!searchSuccess) {
                toast.error("Failed to start search - please try again later");
                updateStatus('triangle-exclamation', 'Search failed', 'text-accent');
                posthog.capture('library_search_failed', { error: 'Failed to start search' });
            }
        } catch (error) {
            console.error(error);
            posthog.capture('library_search_error', { error: error.message });
        } 
    };


    return ( 
        <ViewContainer title="Library search" icon="books">
            <div className="z-[1]">
            <SelectLibrary 
                onButtonClick={handleStartSearch}
                selectedLibrary={selectedLibrary}
                onSelectLibrary={handleSelectLibrary}
                subscriptionTier={subscriptionTier}
                setActiveView={setActiveView}
                buttonIcon="books"
                buttonText="Start search"
                showLibrariesCount={false}
                buttonEnabled={selectedLibrary !== null}
                forceLayout="mobile"
            />
            </div>

            <SearchSettings 
                isVisible={showSettings} 
                startYear={startYear} 
                setStartYear={setStartYear} 
                endYear={endYear} 
                setEndYear={setEndYear} 
                numResults={numResults} 
                setNumResults={setNumResults} 
            />

            <StatusUpdates {...statusUpdates} isVisible={isLoading} />


            <PapersDisplay 
                papers={papers} 
                references={libraryReferences}
                setPapers={setPapers} 
                isLoading={isLoading} 
                listItemParams={{
                    showScore: true,
                    openDetailsOnClick: true,
                }}
                paperDetailsParams={{
                    showButtons: true,
                    searchQuery: searchQuery
                }}
                setSearchQuery={setSearchQuery}
                isActive={isActive}
                user_id={userId}
                isFreeTier={subscriptionTier === 'free'}
                showGraphs={showGraphs}
            />
        </ViewContainer>
    );
};

export default LibrarySearchView;
