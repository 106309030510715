import React, { useState } from 'react';
import { motion } from 'framer-motion'; 

import Icon from './Icon';

const tooltipStyle = `
                        absolute z-[9999] bg-white text-gray-800
                        w-48 left-1/2 transform -translate-x-1/2 
                        border border-primary-dark rounded drop-shadow-lg
                        px-2 py-1 text-sm font-regular
                    `

const tooltipVariants = {
    hidden: { opacity: 0, y: -10, x: '-50%', scale: 0.8,  },
    visible: { opacity: 1, y: '25%', x: '-50%', scale: 1 },
    exit: { opacity: 0, y: -10, x: '-50%', scale: 0.8 },
}


/* -------------------------------------------------------------------------- */
/*                                MAIN BUTTONS                                */
/* -------------------------------------------------------------------------- */

const Button = ({
  children,
  onClick,
  variant = 'primary',
  disabled = false,
  className = '',
  rounded = 'rounded-md',
  padding = 'px-4 py-2',
  fontSize = 'text-base',
  fontWeight = 'font-semibold',
  transition = 'transition duration-200 ease-in-out',
  width = 'w-auto',
  onHover = '',
  onActive = 'active:transform active:scale-95',
  icon=null,
  tooltip,
  ...props
}) => {
  const [showTooltip, setShowTooltip] = useState(false);
  let tooltipTimer = null;

  const handleMouseEnter = () => {
    if (tooltip) {
      tooltipTimer = setTimeout(() => {
        setShowTooltip(true);
      }, 500);
    }
  };

  const handleMouseLeave = () => {
    if (tooltip) {
      clearTimeout(tooltipTimer);
      setShowTooltip(false);
    }
  };

  const baseStyle = `
    ${padding} ${fontSize} ${fontWeight} ${rounded} ${transition} ${width}
    focus:outline-none focus:ring-0 focus:ring-opacity-50
    ${onHover}
    ${onActive}
    disabled:bg-gray-300 disabled:text-gray-500 disabled:border-gray-300 disabled:shadow-none
    whitespace-nowrap overflow-hidden text-ellipsis
    inline-flex items-center justify-center
    group
  `;

  const variants = {
    primary: `
      bg-secondary text-white border border-secondary
    `,
    secondary: `
      bg-white text-secondary border border-secondary
    `,
    accent: `
      bg-accent text-white border border-accent
    `,
  };

  const buttonClass = `${baseStyle} ${variants[variant]} ${className} ${width}`;

  const buttonContent = (
    <button
      className={buttonClass}
      onClick={disabled ? undefined : onClick}
      disabled={disabled}
      {...props}
    >
      {icon && <Icon icon={icon} style="light" className="mr-2" />}
      {children}
    </button>
  );

  if (tooltip) {
    return (
      <div
        className="relative"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        {buttonContent}
        {showTooltip && (
          <motion.div 
            variants={tooltipVariants}
            initial="hidden"
            animate="visible"
            exit="exit"
            transition={{ duration: 0.2 }}
            className={tooltipStyle}
            style={{ zIndex: 9999 }}
          >
            {tooltip}
          </motion.div>
        )}
      </div>
    );
  }

  return buttonContent;
};

export const PrimaryButton = (props) => {
  return <Button variant="primary" {...props} />;
};

export const SecondaryButton = (props) => (
  <Button variant="secondary" {...props} />
);

export const AccentButton = (props) => (
  <Button variant="accent" {...props} />
);

export default Button;


/* -------------------------------------------------------------------------- */
/*                                TOOLTIP BUTTONS                              */
/* -------------------------------------------------------------------------- */

const TooltipButton = ({ icon, onClick, tooltip, className, iconStyle='light', position='relative', disabled=false, iconClassName='', ...props }) => {
    const [showTooltip, setShowTooltip] = useState(false);

    let tooltipTimer = null;

    const handleMouseEnter = () => {
        if (!disabled) {
            tooltipTimer = setTimeout(() => {
                setShowTooltip(true);
            }, 800);
        }
    };

    const handleMouseLeave = () => {
        clearTimeout(tooltipTimer);
        setShowTooltip(false);
    };

    const handleClick = () => {
        if (!disabled) {
            clearTimeout(tooltipTimer);
            setShowTooltip(false);
            if (onClick) {
                onClick();
            }
        }
    };
   
    return (
        <div
            className={`${position} ${className} ${disabled ? 'opacity-50 cursor-not-allowed' : 'cursor-pointer'}`}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            onClick={handleClick}
        >
            <button disabled={disabled}>
                <Icon icon={icon} className={iconClassName} style={iconStyle} />
            </button>
            {showTooltip && !disabled && (
                <motion.div 
                    variants={tooltipVariants}
                    initial="hidden"
                    animate="visible"
                    exit="exit"
                    transition={{ duration: 0.1 }}
                    className={tooltipStyle}
                    style={{ zIndex: 9999 }}
                >   
                    <div className="z-[9999]">
                        {tooltip}
                    </div>
                </motion.div>
            )}
        </div>
    );
}

export {TooltipButton};

const IconButton = ({ icon, onClick, className, iconStyle='light', disabled=false }) => {
  return (
      <div
          className={`relative ${className}`}
          onClick={onClick}
          disabled={disabled}
      >
          <button>
              <Icon icon={icon} style={iconStyle} />
          </button>
      </div>
  );
}

export {IconButton};
