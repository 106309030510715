import React, { useEffect } from 'react';
import ViewContainer from '../elements/ViewContainer';
import Icon from '../elements/Icon';
import PapersDisplay from '../components/PapersDisplay';
import { useUserContext } from '../UserContext';

const BookmarksView = ({ isActive }) => {
    const { bookmarks,  toggleBookmark } = useUserContext();


    const iconStyle = "text-accent/80 text-lg ml-1";
    return (
        <ViewContainer
            title="Bookmarks"
            icon="bookmark"
        >
            {bookmarks.length === 0 ? (
                <>
                <p>
                    You haven't bookmarked any papers yet <Icon icon="rocket" className={iconStyle} />.
                </p>
                <p className='mt-2'>
                    Run a <span className="highlight px-1"> <Icon icon="search" className='' /> Quick Search</span> or
                    a <span className="highlight px-1"> <Icon icon="books" className='' /> Library Search</span> to find papers to bookmark.
                </p>
                </>
            ) : (
                <div className="flex">
                    <PapersDisplay 
                        papers={[...bookmarks].reverse()} 
                        showTopicsMenu={false}
                        isActive={isActive}
                        listItemParams={{
                            showScore: true,
                        }}
                        paperDetailsParams={{
                            showButtons: true,
                            onBookmarkToggle: toggleBookmark
                        }}
                    />
                </div>
            )}
        </ViewContainer>
    );
};

export default BookmarksView;
