import React, { useState, useEffect } from 'react';
import {toast} from 'react-toastify';
import { motion } from 'framer-motion';

import {sendGetRequest,} from '../scripts/Requests';
import  { features } from '../elements/FeaturesCard';
import SubscriptionStatusContainer from '../elements/AccountSubscriptionStatus';
import {  SecondaryButton } from '../elements/Buttons';
import Icon from '../elements/Icon';
import { useUserContext } from '../UserContext';
import AccountStats from '../elements/AccountStats';
import UserCard from '../elements/UserCard';
import { useClerk } from "@clerk/clerk-react";




/* -------------------------------------------------------------------------- */
/*                                 TABS UTILS                                 */
/* -------------------------------------------------------------------------- */

// Remove pastelColors object and related utility functions

// Update TabTitle to use Tailwind classes
const TabTitle = ({ title, colorClass }) => {
  return (
    <p className={`text-3xl font-bold mb-4 ${colorClass}`}>{title}</p>
  );
};

// Update TabSection to use Tailwind classes
const TabSection = ({ title, children, colorClass, height=null }) => {
  return (
    <div className={`flex flex-col gap-4 my-2 pt-2 pb-4 border-b border-gray-200 ${height ? `h-[${height}]` : ''}`}>
      {title && <h3 className={`font-semibold mt-2 ${colorClass}`}>{title}</h3>}
      {children}
    </div>
  );
};





/* -------------------------------------------------------------------------- */
/*                                  MAIN VIEW                                 */
/* -------------------------------------------------------------------------- */

const AccountView = ({ isActive, }) => {
  const { user, userId, subscriptionStatus, trialEnd, customerId } = useUserContext();
  const [activeTab, setActiveTab] = useState('Account');  
  const { signOut } = useClerk();

  const [prices, setPrices] = useState({});

  useEffect(() => {
    const fetchPrices = async () => {
      try {
        const [response, success] = await sendGetRequest(`/backend/get-prices`);
        if (!success) {
          throw new Error('Error fetching prices');
        }
        const data = await response;
        setPrices(data);
      } catch (error) {
        console.error('Error fetching prices:', error);
      }
    };

    fetchPrices();
  }, []);

  const tabs = [
    { name: 'Account', icon: 'user', },
    { name: 'Subscription', icon: 'credit-card', },
    { name: 'App Settings', icon: 'gear', },
  ]

  // Update accountInfoTab to use Tailwind classes and add sign-out button
  const accountInfoTab = () => {
    return (
      <>
        <TabTitle title="Account Details" colorClass="text-account-dark" />
        <TabSection title={null} colorClass="text-account">
          <p className="text-gray-500">You're logged in as:</p>
          <UserCard />

          <button
            onClick={() => signOut()}
            className={
                `
                flex items-center justify-center px-4 py-0.5 bg-gray-200 
                hover:bg-gray-300 text-gray-700 
                rounded transition-colors duration-200
                w-fit my-4 border border-gray-300 hover:border-gray-400 outline-none
                `
            }
          >
            <Icon icon="sign-out-alt" className="mr-2" />
            Log Out
          </button>
        </TabSection>
        <TabSection title="Usage statistics" colorClass="text-account">
          <AccountStats userId={userId} isActive={isActive} iconColor="text-secondary" />
        </TabSection>
      </>
    );
  }

  // Update subscriptionTab to use Tailwind classes
  const subscriptionTab = () => {
    return (
      <div className="subscription-tab overflow-y-auto h-full">
        <TabTitle title="Manage Your Subscription" colorClass="text-subscription-dark" />
        <SubscriptionStatusContainer 
          status={subscriptionStatus} 
          features={features} 
          trialEnd={trialEnd} 
          prices={prices} 
          colorClass="text-subscription" 
          user={user}
        />
        {['inactive', 'cancelled', 'free'].includes(subscriptionStatus) && (
          <div className="manage-subscription mt-8 h-full">
            <p className="mb-4">
              You can manage your subscription through Stripe. You can stop your subscription anytime and be refunded pro-rata for what you haven't used. You will then return to the free tier.
            </p>
            <SecondaryButton
              onClick={() => window.open("https://billing.stripe.com/p/login/test_9AQbMffGj7E31Ta4gg", '_blank')}
            >
              Manage Your Subscription
            </SecondaryButton>
          </div>
        )}
      </div>
    );
  }

  // Update appSettingsTab to use Tailwind classes
  const appSettingsTab = () => {
    return (
      <>
        <TabTitle title="App Settings" colorClass="text-settings-dark" />
        <TabSection title="Automated email recommendations" colorClass="text-settings">
          <p className="text-sm">
              Choose how would you like to receive periodic email recommendations from LitSearch
          </p>
          <p className="text-xl">
            <Icon icon="rocket" className="mr-2" /> Coming soon <Icon icon="rocket" className="ml-2" />
          </p>
        </TabSection>
      </>
    )
  }
 
  const getMenuBG = (tab) => {
    switch (tab) {
      case 'Account': return 'bg-account-light/20';
      case 'Subscription': return 'bg-subscription-light/20';
      case 'App Settings': return 'bg-settings-light/20';
    }
  }

  const getMenuBorderColor = (tab) => {
    switch (tab) {
      case 'Account': return 'border-account-dark';
      case 'Subscription': return 'border-subscription-dark';
      case 'App Settings': return 'border-settings-dark';
    }
  }

  const getMenuColorClass = (tab) => {
    switch (tab) {
      case 'Account': return 'text-account-dark';
      case 'Subscription': return 'text-subscription-dark';
      case 'App Settings': return 'text-settings-dark';
    }
  }


  const isMobile = window.innerWidth < 768;
  const menuBorder = isMobile ? 'rounded-t-lg' : 'rounded-l-lg';
  const containerBorder = isMobile ? 'border-l border-b border-r rounded-b-lg' : 'border-t border-r border-b rounded-r-lg';

  return (
    <div className="account-view w-10/12 mx-auto lg:w-full h-full flex flex-col lg:flex-row overflow-y-auto overflow-visible select-none drop-shadow-lg">


      {/* tabs menu */}
      <div className={` lg:w-1/5 ${menuBorder} p-4 lg:p-8 border flex flex-col gap-4 
         ${getMenuBorderColor(activeTab)} ${getMenuBG(activeTab)}
         `}>
        <div>
          <h3 className={`text-2xl font-semibold mb-1 ${getMenuColorClass(activeTab)}`}>LitSearch Settings</h3>
          <p className="text-sm text-gray-600 mb-4">Manage your account and subscription settings.</p>
        </div>

        {tabs.map((tab) => (
          <div key={tab.name} 
               className={`
                tab-item ${activeTab === tab.name ? `${getMenuColorClass(tab.name)} font-semibold underline` : 'text-gray-700'} 
               cursor-pointer group 
                transition-all duration-150`}
               onClick={() => setActiveTab(tab.name)}>

            <Icon icon={tab.icon} className={`mr-2  `} style="solid" />
            {tab.name}
          </div>
        ))}
      </div>

      {/* main container */}
      <div className={`flex-1 bg-white p-4 lg:p-8 ${containerBorder} ${getMenuBorderColor(activeTab)} overflow-y-auto`}>
        {activeTab === 'Account' && accountInfoTab()}
        {activeTab === 'Subscription' && subscriptionTab()}
        {activeTab === 'App Settings' && appSettingsTab()}
      </div>
    </div>
  );

};

export default AccountView;
