// import { log } from './logger.js';
import * as Sentry from '@sentry/react';


let apiUrl = process.env.REACT_APP_API_URL;


async function sendGetRequest(url, name, requestArguments) {
    try {
        const fullURL = `${apiUrl}${url}`;
        // console.log('Sending GET request to:', fullURL);

        // Construct the query string from requestArguments
        const queryString = requestArguments ? '?' + new URLSearchParams(requestArguments).toString() : '';

        const response = await fetch(fullURL + queryString, );
        if (response.ok) {
            const data = await response.json();
            // console.log(`${name || 'Unnamed'} GET request successful`);
            return [data, true, null]; 
        } else {
            const errorData = await response.json();
            const error = errorData.error || 'Unknown error occurred';

            console.error(`${name || 'Unnamed'} GET request failed`, response, error);
            Sentry.captureException(new Error(`GET request failed: ${error}`));
            return [response, false, error];
        }
    } catch (error) {
        console.error('Error:', error);
        Sentry.captureException(error);
        return [null, false, error];
    }
}

export { sendGetRequest };

// write a function to send post requests
export default async function sendPostRequest(url, body, name, method = 'POST') {
    try {
        const options = {
            method: method,
            headers: {},
        };

        const fullURL = `${apiUrl}${url}`;

        // If data is not FormData and method is not GET or HEAD, stringify it and set Content-Type
        if (!(body instanceof FormData) && !['GET', 'HEAD'].includes(method)) {
            options.body = JSON.stringify(body);
            options.headers = { 
                ...options.headers,
                'Content-Type': 'application/json',
            }
        } else {
            options.body = body;
        }

        const response = await fetch(fullURL, options);

        if (response.ok) {
            const data = await response.json();
            console.log(`${name || 'Unnamed'} ${method} request successful`);
            return [data, true, null]; 
        } else {
            const errorData = await response.json();
            const error = errorData.error || 'Unknown error occurred';
            console.error(`${name || 'Unnamed'} ${method} request failed`, response, error);
            Sentry.captureException(new Error(`${method} request failed: ${response.status} ${response.statusText} - ${error}`));
            return [response, false, error];
        }
    } catch (error) {
        console.error('Error:', error);
        Sentry.captureException(error);
        return [null, false, error];
    }
}

export { apiUrl };
