import React, { useState } from 'react';
import { motion } from 'framer-motion';
import ViewContainer from '../elements/ViewContainer';
import QuickSearchBanner from '../components/QuickSearchBanner';
import PapersDisplay from '../components/PapersDisplay';


const QuickSearchView = ({ isActive}) => {
    const [papers, setPapers] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isSearchCompleted, setIsSearchCompleted] = useState(false);
    const [searchQuery, setSearchQuery] = useState(null);
    const [showGraphs, setShowGraphs] = useState(false);

    const containerVariants = {
        hidden: { opacity: 0, y: -50 },
        visible: { opacity: 1, y: 0, transition: { duration: 0.25 } }
    };

    const papersVariants = {
        hidden: { opacity: 0, y: 50 },
        visible: { opacity: 1, y: 0, transition: { duration: 0.25, delay: 0.1 } }
    };

    return (
        <ViewContainer title="Quick Search" icon="magnifying-glass">
            <motion.div
                initial="hidden"
                animate="visible"
                variants={containerVariants}
            >
                <QuickSearchBanner 
                    papers={papers}
                    setPapers={setPapers}
                    isLoading={isLoading}
                    setIsLoading={setIsLoading}
                    isSearchCompleted={isSearchCompleted}
                    setIsSearchCompleted={setIsSearchCompleted}
                    setShowPromotionBanner={false}
                    hideStatusUpdates={false}
                    origin='quick-search'
                    setSearchQuery={setSearchQuery}
                    setShowGraphs={setShowGraphs}
                />
            </motion.div>
            <motion.div
                initial="hidden"
                animate="visible"
                variants={papersVariants}
            >
                <PapersDisplay 
                    papers={papers} 
                    references={[]}
                    setPapers={setPapers}
                    isLoading={isLoading} 
                    showSmartSort={false} 
                    isActive={isActive}
                    searchQuery={searchQuery}
                    paperDetailsParams={{
                        searchQuery: searchQuery
                    }}
                    showGraphs={showGraphs}
                />
            </motion.div>
        </ViewContainer>
    );
};

export default QuickSearchView;