import React, { useState } from 'react'

import Icon from './Icon'
import SmartSortButton from '../components/SmartSort'
import ResultsGraphs from '../components/ResultsGraphs'



const TopicsMenu = ({ 
    showGraphs,
    topicCounts, 
    keywordCounts, 
    selectedTopic, 
    setSelectedTopic, 
    selectedKeyword, 
    setSelectedKeyword, 
    height,
    onSmartSortButtonClick,
    filteredPapers,
    onSecondLevelFilter,
  }) => {
    const isMobile = window.innerWidth < 1024;
    const [topicsExpanded, setTopicsExpanded] = useState(true);
    const [keywordsExpanded, setKeywordsExpanded] = useState(true);
  
    return (
      <div className={`topics-menu `}>
        <div className="flex flex-col overflow-x-hidden">
          <SmartSortButton onSmartSort={onSmartSortButtonClick} />

          <ResultsGraphs 
            filteredPapers={filteredPapers} 
            showGraphs={showGraphs} 
            onSecondLevelFilter={onSecondLevelFilter}
          />

  
          <div className={`${isMobile ? "h-auto" : height} mt-4 overflow-x-hidden overflow-y-scroll`}>
            {/* TOPICS */}
            <h3 
            className="text-gray-500 pl-1 mb-1 cursor-pointer flex items-center"
            onClick={() => !isMobile && setTopicsExpanded(!topicsExpanded)}
          >
            {!isMobile && (
              <Icon icon={topicsExpanded ? "chevron-down" : "chevron-up"} className="mr-2" />
            )} Topics 
          </h3>
          {(isMobile || topicsExpanded) && (
            <div className="flex lg:flex-col md:flex-row items-start justify-start px-1 py-1 overflow-y-auto">
              {topicCounts.map(([topic, count]) => (
                <button
                  key={topic}
                  className={`pl-2 py-1 rounded-full text-left text-xs mb-2 w-full`}
                  style={{ 
                    opacity: selectedTopic === topic || !selectedTopic ? 1 : 0.5,
                  }}
                  onClick={() => setSelectedTopic(selectedTopic === topic ? null : topic)}
                >
                  <span className="font-semibold text-gray-500">({count})</span> {topic}
                </button>
              ))}
            </div>
          )}
  
          {/* KEYWORDS */}
          {keywordCounts.length > 0 && <>
          <h3 
            className="text-gray-500 pl-1 mb-1 mt-2 cursor-pointer flex items-center"
            onClick={() => !isMobile && setKeywordsExpanded(!keywordsExpanded)}
          >
            {!isMobile && (
              <Icon icon={keywordsExpanded ? "chevron-down" : "chevron-up"} className="mr-2" />
            )} Keywords 
          </h3>
          {(isMobile || keywordsExpanded) && (
            <div className="flex lg:flex-col md:flex-row items-start justify-start px-1 py-1 overflow-y-auto">
              {keywordCounts.slice(0, 10).map(([keyword, count]) => (
                <button
                  key={keyword}
                  className={`pl-2 py-1 rounded-full text-left text-xs mb-2 w-full`}
                  style={{ 
                    opacity: selectedKeyword === keyword || !selectedKeyword ? 1 : 0.5,
                  }}
                  onClick={() => setSelectedKeyword(selectedKeyword === keyword ? null : keyword)}
                >
                  <span className="font-semibold text-gray-500">({count})</span> {keyword}
                </button>
              ))}
            </div>
          )}

          </>}

        </div>
      </div>
      </div>
    );
  };

export default TopicsMenu
