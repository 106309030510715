import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';

import PapersPlot from '../elements/PapersPlot';
import Icon from '../elements/Icon';

const Graph = ({icon, children, label="", iconColor="text-secondary"}) => {
    return (
        <div>
            {children}
            <p className="text-xs text-gray-500 text-center w-full -mt-2">
                <Icon icon={icon} className={`mr-1 ${iconColor}`}/>
                {label}
            </p>
        </div>
    );
};

const ResultsGraphs = ({ filteredPapers, showGraphs, onSecondLevelFilter }) => {
    const [ranges, setRanges] = useState({
        n_citations: null,
        year: null,
        score: null,
    });

    const handleRangeChange = (attribute, range) => {
        setRanges(prevRanges => ({
            ...prevRanges,
            [attribute]: range
        }));
    };

    useEffect(() => {
        // Reset ranges when filteredPapers change
        setRanges({
            n_citations: null,
            year: null,
            score: null,
        });
    }, [filteredPapers]);

    useEffect(() => {
        onSecondLevelFilter && onSecondLevelFilter(ranges);
    }, [ranges, onSecondLevelFilter]);

    if (!showGraphs) {
        return null;
    }

    return (
        <div className={`mt-2 mb-2`}>
            <AnimatePresence>
                <motion.div
                    initial={{ opacity: 0, height: 0 }}
                    animate={{ opacity: 1, height: 'auto' }}
                    exit={{ opacity: 0, height: 0 }}
                    transition={{ duration: 0.2 }}
                >
                    <div className="flex flex-row lg:flex-col gap-4 w-full justify-center ">
                        <Graph icon="chart-line" label="Citations" iconColor="text-[#5981a8]">
                            <PapersPlot 
                                papers={filteredPapers} 
                                attribute="n_citations" 
                                kernelWidth={3} 
                                height={80} 
                                color="#5981a8" 
                                onRangeChange={handleRangeChange}
                                key={`citations-${filteredPapers.length}`}
                            />
                        </Graph>
                        <Graph icon="calendar" label="Year" iconColor="text-[#5981a8]">
                            <PapersPlot 
                                papers={filteredPapers} 
                                attribute="year" 
                                kernelWidth={0.5} 
                                height={80} 
                                color="#5981a8" 
                                onRangeChange={handleRangeChange}
                                key={`year-${filteredPapers.length}`}
                            />
                        </Graph>
                        <Graph icon="fire" label="Score" iconColor="text-accent">
                            <PapersPlot 
                                papers={filteredPapers} 
                                attribute="score" 
                                kernelWidth={1} 
                                // xRange={[0, 100]} 
                                height={80} 
                                color="#e01b50" 
                                onRangeChange={handleRangeChange}
                                key={`score-${filteredPapers.length}`}
                            />
                        </Graph>
                    </div>
                </motion.div>
            </AnimatePresence>
        </div> 
    );
};

export default ResultsGraphs;
