import React, { useState, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import { motion } from 'framer-motion';
import { toast } from 'react-toastify';
import Modal from './Modal';
import Icon from './Icon';
import PrimaryButton from './Buttons';
import StatusUpdates from './StatusUpdates';
import socket from '../scripts/socket';

const FileUploadModal = ({ isOpen, onClose, onUpload, title, children }) => {
    const [selectedFile, setSelectedFile] = useState(null);
    const [isUploading, setIsUploading] = useState(false);
    const [statusUpdates, setStatusUpdates] = useState({});

    const updateStatus = (icon, text, color = "text-gray-400") => {
        setStatusUpdates({
            icon: icon,
            text: text,
            color: color
        });
    };

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        accept: '.bib',
        onDrop: files => {
            setSelectedFile(files[0]);
        }
    });

    useEffect(() => {
        if (isOpen) {
            setStatusUpdates({});
        }
    }, [isOpen]);

    useEffect(() => {
        const handleSocketEvents = (event, data) => {
            switch (event) {
                case 'bib_parsing_started':
                    updateStatus('robot', 'Parsing .bib file...');
                    break;
                case 'bib_parsing_completed':
                    updateStatus('check', `Parsed ${data.n_papers} papers`);
                    break;
                case 'library_creation_started':
                case 'library_update_started':
                    updateStatus('robot', 'Processing library...');
                    break;
                case 'library_creation_progress':
                case 'library_update_progress':
                    updateStatus('robot', `Processing library... ${(data.progress * 100).toFixed(0)}%`);
                    break;
                case 'library_creation_success':
                case 'library_update_success':
                    updateStatus('check', 'Library processed successfully', 'text-secondary');
                    break;
                case 'library_creation_error':
                case 'library_update_error':
                    updateStatus('exclamation-triangle', data.error, 'text-accent');
                    toast.error(data.error);
                    onClose();
                    break;
                case 'library_update_warning':
                    updateStatus('exclamation-triangle', data.message, 'text-accent');
                    toast.warning(data.message);
                    onClose();
                    break;
                case 'library_update_partial':
                    updateStatus('exclamation-triangle', data.message, 'text-accent');
                    toast.info(data.message);
                    onClose();
                    break;
            }
        };

        const events = [
            'bib_parsing_started', 'bib_parsing_completed',
            'library_creation_started', 'library_creation_progress', 'library_creation_success', 'library_creation_error',
            'library_update_started', 'library_update_progress', 'library_update_success', 'library_update_error',
            'library_update_warning'
        ];

        events.forEach(event => {
            socket.on(event, (data) => handleSocketEvents(event, data));
        });

        return () => {
            events.forEach(event => {
                socket.off(event);
            });
        };
    }, []);

    const handleUpload = async () => {
        if (!selectedFile) {
            toast.warning('Please select a .bib file.');
            return;
        }

        setIsUploading(true);
        try {
            await onUpload(selectedFile);
        } catch (error) {
            console.error(error);
            toast.error(error.message || 'Failed to process file');
        } finally {
            setIsUploading(false);
        }
    };

    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <motion.div 
                className="px-4 py-4"
                initial={{ opacity: 0, y: -20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.3 }}
            >
                <h2 className="text-2xl font-bold mb-4 text-gray-600">
                    <Icon icon="light-building-columns-circle-plus" style="kit" className="mr-2 text-accent" />
                    {title}
                </h2>
                
                {children}

                <StatusUpdates {...statusUpdates} isVisible={Object.keys(statusUpdates).length > 0} />
                
                {!isUploading && (
                    <motion.div 
                        {...getRootProps()} 
                        className={`
                            mt-4 border-2 border-secondary/20 
                            py-4 px-8 text-center cursor-pointer
                            bg-primary/50 backdrop-blur-sm
                            text-gray-600 w-full mx-auto rounded-lg
                            hover:bg-secondary/10 transition-colors duration-200
                            ${isDragActive ? 'border-secondary/50 bg-secondary/10' : 'border-secondary/20'}
                        `}
                        whileHover={{ scale: 1.02 }}
                        whileTap={{ scale: 0.98 }}
                    >
                        <input {...getInputProps()} />

                        {isDragActive ? (
                            <p>Drop the file here ...</p>
                        ) : (
                            <div className="flex flex-row items-center w-full py-8">
                                <i className="fas fa-file-upload text-3xl mr-4 text-secondary/80"></i>
                                <p>{selectedFile ? <span>Selected: <span className="highlight-border">{selectedFile.name}</span></span> : <span>Drag and drop a <span className="highlight-border">.bib file </span> here, or click to select a file</span>}</p>
                            </div>
                        )}
                    </motion.div>
                )}

                <div className="mt-6 flex justify-end">
                    <PrimaryButton
                        onClick={handleUpload}
                        disabled={!selectedFile || isUploading}
                    >
                        {isUploading ? 'Processing...' : 'Upload'}
                    </PrimaryButton>
                </div>
            </motion.div>
        </Modal>
    );
};

export default FileUploadModal;
