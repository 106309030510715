import React from 'react';
import { motion } from 'framer-motion';


import Icon from '../elements/Icon';


const SettingInput = ({ 
    placeholder,
    value,
    onChange,
    className,
    vmin = 0,
    vmax = 250,
    valueSetter
}) => {
  const [isFocused, setIsFocused] = React.useState(false);
  const [isInvalid, setIsInvalid] = React.useState(false);
  const [localValue, setLocalValue] = React.useState(value);

  const borderStyles = {
    valid: `hover:border-gray-400 hover:border focus:border-gray-500 focus:border`,
    invalid: `border border-red-500`
  }

  const inputStyle = `w-20 text-center text-sm border bg-white outline-none`

  const handleChange = (e) => {
    const newValue = e.target.value;
    if (/^\d*$/.test(newValue)) {
      setLocalValue(newValue);
      onChange(e);
      setIsInvalid(false);
    } else {
      setIsInvalid(true);
      setTimeout(() => setIsInvalid(false), 1000);
    }
  };

  const handleBlur = () => {
    setIsFocused(false);
    if (valueSetter) {
      const clampedValue = Math.min(Math.max(parseInt(localValue) || vmin, vmin), vmax);
      valueSetter(clampedValue.toString());
      setLocalValue(clampedValue.toString());
    }
  };

  return <input
    type="text"
    placeholder={isFocused ? '' : placeholder}
    value={localValue}
    onChange={handleChange}
    onFocus={() => setIsFocused(true)}
    onBlur={handleBlur}
    className={`${inputStyle} ${className} ${isInvalid ? borderStyles.invalid : borderStyles.valid}`}
  />
}

const SearchSettings = ({ 
    isVisible, 
    startYear, 
    setStartYear, 
    endYear, 
    setEndYear, 
    numResults, 
    numResultsDefault = 200,
    setNumResults 
  }) => {

  const currentYear = new Date().getFullYear();

  const handleYearChange = (e, setter) => {
    const value = e.target.value;
    if (/^\d*$/.test(value)) {
      setter(value);
    }
  };

  const handleNumResultsChange = (e) => {
    const value = e.target.value;
    if (/^\d*$/.test(value) && parseInt(value) <= 250) {
      setNumResults(value);
    }
  };

  const inputStyle = "w-20 text-center text-sm border bg-white outline-none "

  return (
    <motion.div
      initial={{ opacity: 0, height: 0 }}
      animate={{ opacity: isVisible ? 1 : 0, height: isVisible ? 'auto' : 0 }}
      transition={{ duration: 0.3 }}
      className="overflow-hidden w-full mx-auto"
    >
      <div className="flex flex-col sm:flex-row sm:justify-center gap-4 items-center max-w-3xl mx-auto">
        <div className="flex flex-row items-center justify-center gap-0 sm:mb-4 mb-0">
          <div className="flex items-center mr-2">
            <Icon icon="calendar" className="mr-2 text-gray-400" />
            <SettingInput
              placeholder="1900"
              value={startYear}
              onChange={(e) => handleYearChange(e, setStartYear)}
              className={`rounded-l border-r-0`}
              vmin={1900}
              vmax={currentYear}
              valueSetter={setStartYear}
            />
            <SettingInput
              placeholder={currentYear.toString()}
              value={endYear}
              onChange={(e) => handleYearChange(e, setEndYear)}
              className={`rounded-r`}
              vmin={1900}
              vmax={currentYear}
              valueSetter={setEndYear}
            />
          </div>
        </div>
        <div className="flex items-center justify-center">
          <Icon icon="hashtag" className="mr-2 text-gray-400" />
          <SettingInput
            placeholder={numResultsDefault.toString()}
            value={numResults}
            onChange={handleNumResultsChange}
            className={`rounded`}
            vmin={0}
            vmax={250}
            valueSetter={setNumResults}
          />
        </div>
      </div>
    </motion.div>
  );
};

export default SearchSettings;
