import React, { useEffect, useState } from 'react';


import MainAppMenu from '../components/AppMenu';
import AccountView from '../views/AccountView';
import QuickSearchView from '../views/QuickSearchView';
import LibraryView from '../views/LibraryView';
import LibrarySearchView from '../views/LibrarySearchView';
import BookmarksView from '../views/BookmarksView';
import { useUserContext } from '../UserContext';

const MainApp = () => {
  const { user, customerId, userLoaded, trialEnd } = useUserContext();
  const [isMenuCollapsed, setIsMenuCollapsed] = useState(false);
  const [activeView, setActiveView] = useState('Library');
  



  if (!userLoaded || !user) {
    return (
      <div className="loading-screen flex items-center justify-center h-screen w-screen bg-primary">
        <div className={`
          loading-spinner animate-spin rounded-full 
          h-32 w-32 bg-primary border-secondary
          border-t-2 border-b-2
        `}
        ></div>
      </div>
    );
  }


  return (
    <>
    <div id="modal-root"></div>
    <div className="main-app bg-primary min-h-screen w-screen flex flex-col md:flex-row">
      <MainAppMenu 
        isCollapsed={isMenuCollapsed} 
        setIsCollapsed={setIsMenuCollapsed} 
        activeView={activeView}
        setActiveView={setActiveView} 
        trialEnd={trialEnd}
      />
      <main className={`
            view-content
            flex-grow md:py-10 py-6 lg:px-16 
            ${isMenuCollapsed ? 'md:ml-20' : 'md:ml-64'} 
            overflow-y-auto h-screen transition-all duration-300
            ${activeView === 'Account' ? 'bg-primary-dark/20' : ''}
          `}>

        {/* Library */}
        <div className={`${activeView === 'Library' ? 'block' : 'hidden'}`}>
          <LibraryView 
            setActiveView={setActiveView} 
            isActive={activeView === 'Library'}
          />
        </div>

        {/* Quick Search */}
        <div className={`${activeView === 'Search' ? 'block' : 'hidden'}`}>
          <QuickSearchView 
            isActive={activeView === 'Search'}
          />
        </div>

        {/* Library Search */}
        <div className={`${activeView === 'LibrarySearch' ? 'block' : 'hidden'}`}>
          <LibrarySearchView 
            setActiveView={setActiveView} 
            isActive={activeView === 'LibrarySearch'}
          />
        </div>

        {/* Bookmarks */}
        <div className={`${activeView === 'Bookmarks' ? 'block' : 'hidden'}`}>
          <BookmarksView 
            isActive={activeView === 'Bookmarks'}
          />
        </div>

        {/* Account */}
        <div className={`${activeView === 'Account' ? 'block' : 'hidden'} w-full h-full`}>
          <AccountView 
            customerId={customerId}
            isActive={activeView === 'Account'}
          />
        </div>
      </main>
    </div>
    </>
  );
};

export default MainApp;
