import React, { useState, useEffect } from 'react';
import Icon from '../elements/Icon';
import { motion, AnimatePresence } from 'framer-motion';

export const TabContent = ({ title, children }) => {
    return (
      <div className="tab-content px-4 lg:px-8">
        <h2 className="text-4xl font-bold mb-4 text-secondary underline">{title}</h2>
        {children}
      </div>
    );
  };
  
  export const TabContentSubsection = ({ title, children }) => {
    return (
      <div className="tab-content-subsection mt-6">
        <h3 className="text-xl font-semibold mb-2">{title}</h3>
        {children}
      </div>
    );
  };



const TabLayout = ({ tabs, className = '', title = '', description = '' }) => {
  const [activeTab, setActiveTab] = useState(() => {
    const hash = window.location.hash.slice(1);
    const tabIndex = tabs.findIndex(tab => tab.title.toLowerCase().replace(/\s+/g, '-') === hash);
    return tabIndex !== -1 ? tabIndex : 0;
  });
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };
    
    handleResize();
    window.addEventListener('resize', handleResize);

    // Handle hash changes
    const handleHashChange = () => {
      const hash = window.location.hash.slice(1);
      const tabIndex = tabs.findIndex(tab => tab.title.toLowerCase().replace(/\s+/g, '-') === hash);
      if (tabIndex !== -1) {
        setActiveTab(tabIndex);
      }
    };

    window.addEventListener('hashchange', handleHashChange);

    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('hashchange', handleHashChange);
    };
  }, [tabs]);

  useEffect(() => {
    // Update the URL hash when the active tab changes
    const tabTitle = tabs[activeTab].title.toLowerCase().replace(/\s+/g, '-');
    window.history.pushState(null, '', `#${tabTitle}`);
  }, [activeTab, tabs]);

  const menuBorder = isMobile ? 'rounded-t-lg' : 'rounded-l-lg';
  const containerBorder = isMobile ? 'border-l border-b border-r rounded-b-lg' : 'border-t border-r border-b rounded-r-lg';

  const tabStyle = 'cursor-pointer py-1 px-2 text-sm my-1 transition-all duration-150 outline-none text-left w-full border-l-4 rounded-r';
  const tabHoverStyle = 'border-transparent hover:border-secondary/50';
  const tabActiveStyle = 'text-black font-semibold border-secondary ';

  return (
    <div className={`flex flex-col md:flex-row  ${className} w-11/12 mx-auto lg:w-full max-w-7xl mt-12 lg:min-h-[70vh]`}>
      <div className="flex flex-col md:flex-row w-full gap-4">

        {/* MENU */}
        <div className={`md:w-1/5 rounded-lg p-4 lg:py-8 lg:px-8 flex flex-col gap-4 `}>
            {/* menu toggle button */}
            <button
                className="md:hidden mb-4 p-2 bg-primary-dark text-black rounded"
                onClick={() => setIsMenuOpen(!isMenuOpen)}
            >
                {isMenuOpen ? 'Close Menu' : 'Open Menu'}
            </button>
          
          {/* menu title and description */}
          <div className="border-b border-gray-400 pb-2 mb-2">
            <h3 className="text-3xl font-semibold mb-1 text-black">{title}</h3>
            <p className="text-sm text-black">{description}</p>
          </div>

          {/* menu items */}
          <div className={`flex flex-col ${isMenuOpen ? 'block' : 'hidden md:block'}`}>
            {tabs.map((tab, index) => (
              <button
                key={index}
                onClick={() => setActiveTab(index)}
                className={`${tabStyle}
                  ${activeTab === index ? tabActiveStyle : tabHoverStyle}`}
              >
                <div className="flex items-center">
                  {tab.icon && <Icon icon={tab.icon} className="mr-2" />}
                  {tab.title}
                </div>
              </button>
            ))}
          </div>
        </div>

        {/* CONTENT */}
        <div className={`md:w-4/5 rounded-lg p-4 md:p-8 border border-gray-400 overflow-y-auto bg-white drop-shadow-lg`}>
          <AnimatePresence mode="wait">
            <motion.div
              key={activeTab}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
              transition={{ duration: 0.3 }}
            >
              {tabs[activeTab].content}
            </motion.div>
          </AnimatePresence>
        </div>
      </div>
    </div>
  );
};

export default TabLayout;
