import React, { useState } from 'react';
import { motion } from 'framer-motion';

import { loadStripe } from '@stripe/stripe-js';


import { UsageLimitsFree, UsageLimitsPro } from '../scripts/UsageLimits';
import { PrimaryButton, } from './Buttons';
import Icon from './Icon';


const API_ENV = process.env.NODE_ENV === 'production' ? 'production' : 'development';

let MONTHLY_PRICE_ID;
let YEARLY_PRICE_ID;
let STRIPE_PUBLISHED_KEY;

if (API_ENV === 'production') {
  MONTHLY_PRICE_ID = 'price_1QDmbSKE02kLx3zrrtHlSGbG';
  YEARLY_PRICE_ID = 'price_1QDmbLKE02kLx3zrv0zvWPS7';
  STRIPE_PUBLISHED_KEY = process.env.REACT_APP_STRIPE_PUBLISHED_KEY;
} else {
  STRIPE_PUBLISHED_KEY = process.env.REACT_APP_STRIPE_TEST_PUBLISHED_KEY;
  MONTHLY_PRICE_ID = 'price_1QDmZKKE02kLx3zrwxY8dlX8';
  YEARLY_PRICE_ID = 'price_1QDmZKKE02kLx3zr7n7lS1sF';
}

const stripePromise = loadStripe(STRIPE_PUBLISHED_KEY);



export {
  MONTHLY_PRICE_ID,
  YEARLY_PRICE_ID,
  STRIPE_PUBLISHED_KEY,
  stripePromise,
  features
};

const features = [
  ["libraries", "", "building-columns", UsageLimitsFree.myLibraries, UsageLimitsPro.myLibraries],
  ["papers per library", "", "file-lines", UsageLimitsFree.maxLibrarySize, UsageLimitsPro.maxLibrarySize],
  ["Quick Searches", "/week", "magnifying-glass", UsageLimitsFree.quickSearches, UsageLimitsPro.quickSearches],
  ["Library Searches", "/week", "books", UsageLimitsFree.librarySearches, UsageLimitsPro.librarySearches],
  ["Smart Sort", "/week", "sort", UsageLimitsFree.smartSort, UsageLimitsPro.smartSort],
  ["bookmarks", "", "bookmark", UsageLimitsFree.bookmarks, UsageLimitsPro.bookmarks],
  ["", "", "file-arrow-down", UsageLimitsFree.exportRecommendations, UsageLimitsPro.exportRecommendations],
  ["", "", "robot", UsageLimitsFree.weeklyRecommendations, UsageLimitsPro.weeklyRecommendations],
];



const FeaturesCard = ({ features, onSubscribe, loading, prices }) => {
  const [billingCycle, setBillingCycle] = useState('monthly');

  const toggleStyle = `px-4 py-1 text-sm font-medium`;
  const activeToggleStyle = `bg-secondary text-white border-secondary`;
  const inactiveToggleStyle = `bg-gray-100 text-gray-600 border-secondary hover:bg-gray-200`;

  const BillingCycleToggle = ({ billingCycle, setBillingCycle, prices }) => {
    const monthlyPrice = prices[MONTHLY_PRICE_ID];
    const yearlyPrice = prices[YEARLY_PRICE_ID];
    const yearlyPricePerMonth = (yearlyPrice / 12).toFixed();

    return (
      <div className="flex flex-row items-end justify-center mb-4 gap-8">

        <div className="flex flex-col items-center justify-center">
          <div className="text-3xl font-semibold text-secondary mb-1">
            ${billingCycle === 'monthly' ? monthlyPrice : yearlyPricePerMonth}
            <span className="text-sm text-gray-500 font-normal">/month</span>
          </div>
          {billingCycle === 'yearly' && (
            <div className="text-sm text-center text-gray-600">${yearlyPrice} billed annually</div>
          )}
          {billingCycle === 'monthly' && (
            <div className="text-sm text-center text-gray-600">Select yearly, save 20%!</div>
          )}
        </div>
        <div className="inline-flex rounded" role="group">
          <button
            type="button"
            className={`${toggleStyle} rounded-l border ${
              billingCycle === 'monthly' ? activeToggleStyle : inactiveToggleStyle
            }`}
            onClick={() => setBillingCycle('monthly')}
          >
            Monthly
          </button>
          <button
            type="button"
            className={`${toggleStyle} rounded-r border-t border-b border-r ${
              billingCycle === 'yearly' ? activeToggleStyle : inactiveToggleStyle
            }`}
            onClick={() => setBillingCycle('yearly')}
          >
            Yearly
          </button>
        </div>
      </div>
    );
  };


  const price = billingCycle === 'monthly' ? prices[MONTHLY_PRICE_ID] : (prices[YEARLY_PRICE_ID] / 12).toFixed(1);

  return (
    <motion.div
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
      className="w-full"
    >
      <BillingCycleToggle billingCycle={billingCycle} setBillingCycle={setBillingCycle} prices={prices} className="mb-6" />

      {/* main card */}
      <motion.div
        initial={{ scale: 0.95 }}
        animate={{ scale: 1 }}
        transition={{ duration: 0.3, delay: 0.2 }}
        className="w-full lg:w-2/3 mx-auto rounded-lg lg:px-8 py-4"
      >
        <div className="w-full flex justify-between mb-2 pb-2 gap-8">
          <p className="text-gray-500 font-semibold w-1/4 text-right">Free</p>
          <p className="text-xl font-semibold text-secondary w-3/4">
            <Icon icon="flame" className="mr-2" style="light" />
            LitSearch Pro
            </p>
        </div>

        <div className="w-full">
          {features.map(([feature, freeText, icon, freeValue, proValue]) => (
            <motion.div
              key={`pro-${feature}`}
              className="flex items-center mb-2 text-xs lg:text-sm"
              whileHover={{ scale: 1.05, transition: { duration: 0.2 } }}
            >
              {/* free */}
              <span className="mx-1 text-gray-500 w-20 text-right mr-4">{freeValue ? `${freeValue}${freeText}` : <Icon icon="ban" className="text-red-500/50" style="solid" />}</span> 

              <Icon icon="arrow-right" className="mr-4 text-gray-500" /> 

              {/* pro and feature */}
              <span className="mr-1 px-1 bg-secondary/10 border-b-2 border-secondary/50 text-secondary">
                <Icon icon={icon} className="text-secondary mr-2" style="regular" />
                {proValue == Infinity ? "Unlimited" : proValue}
              </span>
              <span>{feature}</span>

            </motion.div>
          ))}
        </div>
      </motion.div>

      {/* signup */}
      <div className="w-10/12 lg:w-1/3 mx-auto">
          <PrimaryButton
            onClick={() => onSubscribe(billingCycle === 'monthly' ? MONTHLY_PRICE_ID : YEARLY_PRICE_ID)}
            disabled={loading}
            fontWeight="font-semibold"
            className="py-1 text-lg border-white w-full transition-all duration-300"
            onHover="hover:scale-105"
          >
            {loading ? (
              <Icon icon="spinner" className="animate-spin mr-2" style="light" />
            ) : (
              <Icon icon="rocket" className="mr-2" style="light" />
            )}
            {loading ? 'Processing...' : 'Start your 14 days trial'}
          </PrimaryButton>
        </div>
    </motion.div>
  );
};

export default FeaturesCard;
