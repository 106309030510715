import React, { useState } from 'react';
import { SignedIn, SignedOut, UserButton } from "@clerk/clerk-react";
import { AccentButton, SecondaryButton } from './Buttons'; // Make sure to import SecondaryButton

import Modal from './Modal';

const TopNavBar = ({ userName, nSearches, hideLinks = false, brandColor="text-secondary" }) => {
  const [isAboutModalOpen, setIsAboutModalOpen] = useState(false);
  const [isInstructionsModalOpen, setIsInstructionsModalOpen] = useState(false);

  return (
    <nav className="z-30">
      <div className="container mx-auto px-4">
        <div className="flex flex-col sm:flex-row items-center justify-between py-4">

          {/* BRAND */}
          <div className="flex items-center mb-4 gap-2 sm:mb-0 cursor-pointer" onClick={() => window.location.href = '/'}>
            <img src={process.env.PUBLIC_URL + '/logo.png'} alt="LitSearch logo" className="h-12 w-auto mr-2"/>
            <span className={`text-5xl ${brandColor}  font-bold`}>LitSearch</span>
          </div>
          
          {!hideLinks && (
          <div className="flex items-center space-x-4">
            
            <SignedIn>
              <div className="flex items-center space-x-2">
                {userName && <span className="text-sm">{userName.slice(0, 20)}</span>}
                {nSearches && <span className="text-sm">({5 - nSearches}/5 searches)</span>}
                <UserButton />
              </div>
            </SignedIn>
            <SignedOut>
              <AccentButton 
                onClick={() => window.location.href = '/signup'}
              >
                Sign up
              </AccentButton>
              <SecondaryButton 
                onClick={() => window.location.href = 'https://accounts.litsearch.io/sign-in'}
              >
                Sign in
              </SecondaryButton>
            </SignedOut>
          </div>
          )}
        </div>
      </div>

      <Modal isOpen={isAboutModalOpen} onClose={() => setIsAboutModalOpen(false)}>
        <div className="modal_title">
          About LitSearch
        </div>
        <div className="modal_content">
          <p>Experience the future of academic literature discovery.</p>

          <b>🧠 Intelligent Recommendations</b>
          <p>
            Our AI-powered system learns from your reading history to deliver personalized, relevant research suggestions.
            As your interests evolve, so do our recommendations, ensuring you stay at the forefront of your field.
          </p>

          <b>⏱️ Time-Saving Efficiency</b>
          <p>
            Eliminate hours of manual searching. Simply describe your research needs as you would to a colleague,
            and LitSearch interprets your request to find the most interesting papers.
          </p>

          <b>🎯 Precision-Focused Results</b>
          <p>
            We're committed to delivering the most relevant papers for your research.
            Our innovative algorithms and features are designed to pinpoint exactly what you need,
            when you need it.
          </p>

          <b>Why a demo?</b>
          <p>
            LitSearch is under active development, and we're actively seeking community feedback to refine our product.
            With this demo, we want to give you a chance to see how LitSearch works and provide feedback on the recommendations.
            We're also running a pre-sale for the LitSearch trial. For just 1$, you'll have accesss to all of LitSearch's features for 3 months
            after launch.
          </p>

          <p>
            Questions or feedback? Contact us at <a href="mailto:contact@litsearch.io">contact@litsearch.io</a>.
          </p>
        </div>
      </Modal>

      <Modal isOpen={isInstructionsModalOpen} onClose={() => setIsInstructionsModalOpen(false)}>
        <div className="modal_title">
          Instructions
        </div>
        <div className="modal_content">
          <b>Using LitSearch</b>

          <b>LitSearch Demo</b>
          <p>
            You have access to the LitSearch demo. You can use the demo to run
            6 searches. This should give you a feel for how the product will work wile we 
            endeavor to improve the product based on your feedback.
            Once you've completed the demo, you'll have the opportunity to sign up for the LitSearch
            trial pre-sale. For just 1$, you'll have accesss to all of LitSearch's features for 3 months
            after launch.
          </p>

          <b>Uploading your library</b>
          <p>
            LitSearch uses metadata about <i>several </i> papers that you've read to find the next
            best recommendations. To get started, you need to upload a <code>.bib</code> file containing
            the metadata of the papers you've read. 
            You can find BibTeX references in the papers you've read, on Google Scholar, and in most reference manager software. You should include at least 5 references to get good recommendations and try to include references on specific topics you're interested in. LitSearch will aim to find recommendations based on the topics covered in the references you provide. Once you have a <code>.bib</code> file ready, you can upload it by pressing the corresponding button. If you don't have a <code>.bib</code> file, you can use the example file provided.
          </p>

          <b>Refining the search</b>
          <p>
            Once the search is completed, we'll show you the papers most relevant based on your library.
            If you want to focus on a specific topic, you can use <i>Smart Sort</i>. With Smart Sort, 
            you can describe your focus in a few words, as if you were talking to a colleague.
            For example, you can say: "Focus on papers about the effects of climate change on agriculture in developing regions"
            or "Give priority to papers focused on animal studies instead of mathematical models".
            LitSearch will then sort papers based on your focus to make sure you get exactly what you're looking for.
          </p>

          <b>Giving feedback</b>
          <p>
            LitSearch is in active development. Things are not perfect yet, and you might find some bugs.
            If you do, please report them to us at <a href="mailto:contact@litsearch.io">contact@litsearch.io</a>.
            If you have any suggestions on how to improve LitSearch, we'd love to hear them - and if you've
            found the recommendations useful, please let us know!
          </p>

        </div>
      </Modal>
    </nav>
  );
};

export default TopNavBar;