import React from 'react';
import { motion } from 'framer-motion';
import { SignUp } from '@clerk/clerk-react';


import Icon from '../elements/Icon';
import TopNavBar from '../elements/TopNavBar';

const HelpPage = () => {
  return (
    <div className="signup-page min-h-screen min-w-screen bg-primary pb-10 select-none">
      <TopNavBar hideLinks={true} />
    
    </div>
  );
};

export default HelpPage;
