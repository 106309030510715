import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';

import Icon from './Icon';
import { sendGetRequest } from '../scripts/Requests';


/* -------------------------------------------------------------------------- */
/*                                  MAIN DIV                                  */
/* -------------------------------------------------------------------------- */
const AccountStats = ({ userId, isActive, iconColor }) => {
    const [stats, setStats] = useState({});
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
  
    useEffect(() => {
      const fetchStats = async () => {
        setLoading(true);
        setError(null);
        try {
          const [response, success] = await sendGetRequest(`/backend/user-stats?user_id=${userId}`);
          if (success) {
            setStats(response);
          } else {
            setError('Failed to fetch user stats');
          }
        } catch (err) {
          setError('An error occurred while fetching user stats');
          console.error('Error fetching user stats:', err, userId);
        } finally {
          setLoading(false);
        }
      };
  
      fetchStats();
    }, [userId, isActive]);
  
  
    const containerVariants = {
      hidden: { opacity: 0 },
      visible: {
        opacity: 1,
        transition: {
          staggerChildren: 0.1
        }
      }
    };
  
  
    return (
      <motion.div 
      className="w-full pb-4 mx-auto"
      variants={containerVariants}
      initial="hidden"
      animate="visible"
    >
      {loading && <div>Loading stats... <Icon icon="spinner" className="animate-spin" /></div>}
      {error && <div>Error: {error} <Icon icon="triangle-exclamation" className="text-accent" /></div>}
      {stats && (
        <div className="grid grid-cols-3 grid-rows-2 gap-2 px-4 lg:px-8 w-full mx-auto">
          <StatItem label={`${stats.library_created !== 1 ? 'libraries' : 'library'} created`} value={stats.library_created || 0} icon="building-columns" iconColor={iconColor} />
          <StatItem label={`${stats.quick_searches !== 1 ? 'quick searches' : 'quick search'}`} value={stats.quick_searches || 0} icon="magnifying-glass" iconColor={iconColor}/>
          <StatItem label={`${stats.library_searches !== 1 ? 'library searches' : 'library search'}`} value={stats.library_searches || 0} icon="books" iconColor={iconColor}/>
          <StatItem label={`${stats.smart_sorts !== 1 ? 'smart sorts' : 'smart sort'}`} value={stats.smart_sorts || 0} icon="fire" iconColor={iconColor}/>
          <StatItem label={`${stats.papers_bookmarked !== 1 ? 'papers bookmarked' : 'paper bookmarked'}`} value={stats.papers_bookmarked || 0} icon="bookmark" iconColor={iconColor}/>
          <StatItem label={`${stats.papers_added_to_library !== 1 ? 'papers added' : 'paper added'}`} value={stats.papers_added_to_library || 0} icon="plus-circle" iconColor={iconColor} />
        </div>
      )}


      </motion.div>
    );
  };
  

/* -------------------------------------------------------------------------- */
/*                                  STAT ITEM                                 */
/* -------------------------------------------------------------------------- */
const StatItem = ({ label, value, icon, iconColor }) => {

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.5,

      }
    }
  };

  return (
    <motion.div 
      className="text-sm"
      variants={itemVariants}
    >
      <Icon icon={icon} className={`${iconColor} text-sm mr-2`} style="regular" />
      <span className="text-lg font-semibold text-sm mr-1">{value}</span>
      <span className="text-sm">{label}</span>
    </motion.div>
  );
};

  export default AccountStats;
