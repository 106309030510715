import React from 'react';
import { toast } from 'react-toastify';
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react';
import { motion } from 'framer-motion';
import { useUserContext } from '../UserContext';

import Icon from './Icon';
import { PrimaryButton, TooltipButton } from './Buttons';

const toastConfig = {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
}

/* -------------------------- select library button ------------------------- */

const SelectLibraryButton = ({ children, onClick, disabled = false}) => {
    return (
        <button
            className={`
                group 
                flex 
            
                lg:rounded-l
                md:mb-2
                lg:mb-0
                items-center 
                w-full px-4 py-2 
                text-base
                text-secondary
                hover:shadow
                active:bg-secondary/10
                bg-white border border-secondary
            `}
            onClick={onClick}
            disabled={disabled}
        >
            {children}
        </button>
    )
}

/* -------------------------------------------------------------------------- */
/*                               SELECT LIBRARY                               */
/* -------------------------------------------------------------------------- */

const SelectLibrary = ({ 
    onButtonClick, 
    selectedLibrary, 
    onSelectLibrary, 
    setActiveView,
    buttonIcon="plus",
    buttonText="New Library",
    showLibrariesCount=true,
    buttonEnabled=true,
    forceLayout=null,
    libraryBeingCreated = null,  
}) => {
    const { subscriptionTier, libraries } = useUserContext();
    const isFreeTier = subscriptionTier === 'free';
    const libraryCount = libraries.length;

    const handleUpgradeClick = () => {
        toast.info(
            <div>
                Upgrade to <span className="highlight-border">LitSearch Pro</span> in 
                <span onClick={() => setActiveView('Account')} className="text-secondary hover:underline ml-2">your account settings. </span>
            </div>,
            { ...toastConfig, autoClose: 8000 }
        );
    };

    const placeholderVariants = {
        hidden: { opacity: 0, y: 20 },
        visible: { opacity: 1, y: 0 }
    };

    const addHintTextColor = libraries.length === 0 ? 'text-gray-600' : 'text-gray-400';
    const addHintSpanClass = libraries.length === 0 ? 'highlight px-1' : '';

    const DesktopView = () => (
        <>
        <p className="text-xl font-primary-dark font-semibold mb-2 pl-4 w-full">Select a library</p>
        <p className={`text-sm ${addHintTextColor} mb-4 pl-4`}>
            <span className={addHintSpanClass}>Click on <Icon icon="light-building-columns-circle-plus" className="inline mx-1" style="kit" /></span> to create a new library
        </p>
        <div className="flex-col bg-white rounded-lg border px-4 py-2 w-full">
            
            <div className="flex flex-row items-center justify-between mb-2 border-b border-gray-200">
            <p className="text-sm text-gray-400 border-b border-transparent">{libraryCount}
                {libraryCount === 1 ? ' library' : ' libraries'}
            </p>
            <TooltipButton
               icon="light-building-columns-circle-plus"
               tooltip="Create a new library"
               onClick={onButtonClick}
               iconStyle="kit"
               className={`
                    text-lg
                    transition-all duration-300 text-gray-500 
                    hover:bg-secondary hover:text-white
                    border-b border-transparent
                    rounded px-2 py-1
                    w-fit
                    ml-3
                    cursor-pointer
                `}
                />
                
            </div>
            {isFreeTier && showLibrariesCount && (
                <p className="mt-4 text-sm opacity-40 cursor-pointer text-center mb-4" onClick={handleUpgradeClick}>
                    Unlock more libraries with 
                    <span className="highlight-border"> LitSearch Pro</span>
                </p>
            )}

            <div className="overflow-y-auto max-h-[60vh] w-full">
                {libraryBeingCreated && (
                    <div className="flex items-center justify-between px-2 py-1 mb-1 border-l-4 border-secondary/20 bg-secondary/5">
                        <div>
                            <div className="font-semibold">{libraryBeingCreated}</div>
                            <div className="text-sm opacity-75">Creating...</div>
                        </div>
                    </div>
                )}
                {libraries.map((library) => (
                    <button
                        key={library.id}
                        className={`
                            w-full text-left px-2 py-1 mb-1 border-l-4
                            ${selectedLibrary && selectedLibrary.id === library.id 
                                ? 'border-secondary' 
                                : 'border-transparent hover:border-secondary/50 hover:text-secondary'
                            }
                        `}
                        onClick={() => onSelectLibrary(library)}
                    >
                        <div className="font-semibold">{library.name}</div>
                        <div className="text-sm opacity-75">{library.paperCount || 0} papers</div>
                    </button>
                ))}
            </div>
        </div>

        </>
    );

    const MobileView = () => (
        <div className="w-4/5 mx-auto flex flex-col lg:flex-row flex-start lg:flex-center">
            <Menu as="div" className="relative inline-block text-left w-full">
                <MenuButton as={SelectLibraryButton} className="inline-flex items-center w-full gap-4">
                    {selectedLibrary ? 
                        <Icon icon="book-open" className="mr-4" style='light' /> : 
                        <Icon icon="book" className="mr-4" style='light' />
                    }
                    {selectedLibrary ? (
                        selectedLibrary.name
                    ) : libraryBeingCreated ? (
                        <span className="flex items-center">
                            <span className="mr-2">{libraryBeingCreated}</span>
                            <div className="animate-spin rounded-full h-4 w-4 border-t-2 border-b-2 border-secondary"></div>
                        </span>
                    ) : (
                        <motion.span
                            initial="hidden"
                            animate="visible"
                            variants={placeholderVariants}
                            transition={{ duration: 0.5, delay: 0.2 }}
                        >
                            {libraryCount > 0 ? "Select Library" : "No libraries found, create one first."}
                        </motion.span>
                    )}
                </MenuButton>

                <MenuItems className="absolute left-0 mt-2 w-56 origin-top-left bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <div className="px-1 py-1">
                        {libraries.length > 0 ? libraries.map((library) => (
                            <MenuItem key={library.id}>
                                <button
                                    className="data-[focus]:bg-secondary data-[focus]:text-white text-secondary group flex rounded-md items-center w-full px-2 py-2 text-sm"
                                    onClick={() => onSelectLibrary(library)}
                                >
                                    {library.name}
                                </button>
                            </MenuItem>
                        )) : (
                            <MenuItem>
                                <button className="text-gray-900 group flex rounded-md items-center w-full px-2 py-2 text-sm cursor-default">
                                    No libraries found
                                </button>
                            </MenuItem>
                        )}
                    </div>
                </MenuItems>
            </Menu>

            
            <PrimaryButton 
                onClick={onButtonClick}
                rounded="lg:rounded-r "
                padding="px-4 py-2"
                fontWeight="text-base"
                onHover=""
                width="w-full lg:w-auto mt-0"
                disabled={!buttonEnabled}
            >
                <Icon icon={buttonIcon} className="mr-2" style='light' />
                <span className="group-hover:underline">{buttonEnabled ? buttonText : 'Select a library'}</span>
            </PrimaryButton>
    
            {isFreeTier && showLibrariesCount && (
                <p className="mt-4 text-sm opacity-40 cursor-pointer text-center mb-4" onClick={handleUpgradeClick}>
                    Unlock more libraries with 
                    <span className="highlight-border"> LitSearch Pro</span>
                </p>
            )}
        </div>
    );

    // Determine which view to render based on forceLayout prop
    const renderView = () => {
        if (forceLayout === 'mobile') {
            return <MobileView />;
        } else if (forceLayout === 'desktop') {
            return <DesktopView />;
        } else {
            return (
                <>
                    <div className="hidden lg:block">
                        <DesktopView />
                    </div>
                    <div className="lg:hidden">
                        <MobileView />
                    </div>
                </>
            );
        }
    };

    return renderView();
};

export default SelectLibrary;
export { SelectLibraryButton };
