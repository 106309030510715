import React from 'react';

import TopNavBar from '../elements/TopNavBar';
import TabLayout, { TabContent, TabContentSubsection } from '../components/TabLayout';


// GETTING STARTED
const gettingStartedContent = (
  <TabContent title="Getting Started">
    <p>Welcome to our app! Here's how to get started...</p>
    <TabContentSubsection title="Create an Account">
      <p>To get started, create an account by...</p>
    </TabContentSubsection>
    <TabContentSubsection title="Navigating the Dashboard">
      <p>Once logged in, you'll see your dashboard...</p>
    </TabContentSubsection>
  </TabContent>
);

// USING THE APP
const usingContent = (
  <TabContent title="Features">
    <p>Explore our amazing features...</p>
    <TabContentSubsection title="Search">
      <p>Our powerful search feature allows you to...</p>
    </TabContentSubsection>
    <TabContentSubsection title="Saved Lists">
      <p>Create and manage your saved lists by...</p>
    </TabContentSubsection>
  </TabContent>
);

// MANAGING YOUR ACCOUNT
const managingAccountContent = (
  <TabContent title="FAQ">
    <p>Frequently asked questions...</p>
    <TabContentSubsection title="Account Issues">
      <p>Q: How do I reset my password?</p>
      <p>A: To reset your password, go to...</p>
    </TabContentSubsection>
    <TabContentSubsection title="Billing">
      <p>Q: How do I update my payment method?</p>
      <p>A: To update your payment method, navigate to...</p>
    </TabContentSubsection>
  </TabContent>
);

// SUPPORT
const supportContent = (
  <TabContent title="Contact Support">
    <p>Need more help? Contact our support team...</p>
    <TabContentSubsection title="Email Support">
      <p>For non-urgent inquiries, email us at support@example.com</p>
    </TabContentSubsection>
    <TabContentSubsection title="Live Chat">
      <p>For immediate assistance, use our live chat feature available...</p>
    </TabContentSubsection>
  </TabContent>
);

// HELP PAGE
const HelpPage = () => {
  const helpTabs = [
    { title: 'Getting Started', content: gettingStartedContent },
    { title: 'Using LitSearch', content: usingContent },
    { title: 'Managing your account', content: managingAccountContent },
    { title: 'Support', content: supportContent },
  ];

  return (
    <div className="help-page min-h-screen min-w-screen bg-primary-light pb-10 select-none">
      <TopNavBar hideLinks={true} />
      <TabLayout tabs={helpTabs} className="" title="Help" description="Find help and support for LitSearch." />
    </div>
  );
};

export default HelpPage;
