import React from 'react';
import { Helmet } from 'react-helmet';
import '@fortawesome/fontawesome-svg-core/styles.css';



import '../style/style.css';


const HeadTags = () => {
  return (
    <Helmet>
          <meta charset="UTF-8" />
          <meta name="viewport" content="width=device-width, initial-scale=1.0" />
          <meta http-equiv="X-UA-Compatible" content="ie=edge" />

          <title>LitSearch</title>
          <link rel="icon" type="image/x-icon" href="/favicon.ico" />
          <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32.png" />
          <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16.png" />
          <link rel="apple-touch-icon" sizes="180x180" href="/favicon-180.png" />
          <link rel="manifest" href="/site.webmanifest" />
          
          <link href="https://fonts.googleapis.com/css2?family=Inter:wght@400;600;700&family=Fira+Code&display=swap" rel="stylesheet"/>
          <script src="https://kit.fontawesome.com/63bbb160c9.js" crossorigin="anonymous"></script>

          <link rel="stylesheet"
              href="https://fonts.googleapis.com/css?family=Nunito Sans"/>
          <link rel="stylesheet"
              href="https://fonts.googleapis.com/css?family=Orbitron"/>
          <link rel="stylesheet"
              href="https://fonts.googleapis.com/css?family=Teko"/>
          <link rel="stylesheet"
              href="https://fonts.googleapis.com/css?family=Cormorant Upright "/>
          <link rel="stylesheet"
              href="https://fonts.googleapis.com/css?family=Cormorant Infant"/>
          <link rel="stylesheet"
              href="https://fonts.googleapis.com/css?family=Space Mono"/>
          <link rel="stylesheet"
              href="https://fonts.googleapis.com/css?family=Kaushan Script "/>
          <link rel="stylesheet"
              href="https://fonts.googleapis.com/css?family=Alegreya "/>
          <link rel="stylesheet"
              href="https://fonts.googleapis.com/css?family=Poppins"/>
          <link rel="stylesheet"
              href="https://fonts.googleapis.com/css?family=Rosario"/>
          <link rel="stylesheet"
              href="https://fonts.googleapis.com/css?family=DM Serif Display"/>
          <link rel="stylesheet"
              href="https://unpkg.com/bulma@0.9.0/css/bulma.min.css" />
          <script src="https://code.jquery.com/jquery-3.6.4.min.js"></script>
          <script src="https://js.stripe.com/v3/"></script>
          <link href="https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap" rel="stylesheet"></link>

            {/* Open Graph / Facebook */}
            <meta property="og:type" content="website" />
            <meta property="og:url" content="https://litsearch.io/" />
            <meta property="og:title" content="LitSearch - A powerful literature search tool" />
            <meta property="og:description" content="Discover and explore academic literature with ease using LitSearch, your go-to tool for efficient research." />
            <meta property="og:image" content="https://litsearch.io/og-image.jpg" />

            {/* Twitter */}
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:url" content="https://litsearch.io/" />
            <meta name="twitter:title" content="LitSearch - A powerful literature search tool" />
            <meta name="twitter:description" content="Discover and explore academic literature with ease using LitSearch, your go-to tool for efficient research." />
            <meta name="twitter:image" content="https://litsearch.io/twitter-image.jpg" />

            <script async src="https://js.stripe.com/v3/pricing-table.js"></script>

      </Helmet>
  );
};

export default HeadTags;
