import { useState, useEffect, useCallback } from 'react';
import { toast } from 'react-toastify';
import { UsageLimitsFree } from '../scripts/UsageLimits';
import sendPostRequest, { sendGetRequest } from '../scripts/Requests';
import { useUserContext } from '../UserContext';

import StatusUpdates from '../elements/StatusUpdates';
import socket from '../scripts/socket';
import TextInput from '../elements/TextInput';
import PrimaryButton from '../elements/Buttons';
import Modal from '../elements/Modal';
import Icon from '../elements/Icon';
import { motion } from 'framer-motion';
import FileUploadModal from '../elements/FileUploadModal';



// Check number of libraries limit for free users
export const useLibraryLimitCheck = () => {
    const { userId, subscriptionTier, libraries, setLibraries } = useUserContext();
    const [isLoading, setIsLoading] = useState(true);

    const fetchLibraries = async () => {
        setIsLoading(true);
        try {
            const [response, success] = await sendGetRequest(`/backend/libraries?user_id=${userId}`, 'get-user-libraries');
            if (success) {
                setLibraries(response);
            } else {
                throw new Error('Failed to fetch libraries');
            }
        } catch (error) {
            console.error('Error fetching libraries:', error);
            toast.error('Failed to fetch libraries. Please try again.');
        } finally {
            setIsLoading(false);
        }
    };

    const checkLibraryLimit = () => {
        if (subscriptionTier === 'pro') {
            return true;
        }

        const MAX_FREE_LIBRARIES = UsageLimitsFree.myLibraries;

        if (libraries.length >= MAX_FREE_LIBRARIES) {
            toast.error('You have reached the maximum number of libraries for a free account. Please upgrade to create more libraries.');
            return false;
        }

        return true;
    };

    return { checkLibraryLimit, isLoading, libraryCount: libraries.length, fetchLibraries };
};

const CreateNewLibraryFromFileModal = ({ onClose, onUpload, isOpen, setIsOpen }) => {
    const [libraryName, setLibraryName] = useState('');
    const { checkLibraryLimit, fetchLibraries } = useLibraryLimitCheck();

    const handleUpload = async (file) => {
        if (!libraryName.trim()) {
            toast.warning('Please specify a library name.');
            return;
        }

        if (!checkLibraryLimit()) {
            return;
        }

        await onUpload(file, libraryName);
        fetchLibraries(); // Update the libraries list after creation
    };

    return (
        <FileUploadModal
            isOpen={isOpen}
            onClose={() => setIsOpen(false)}
            onUpload={handleUpload}
            title="Add New Library"

        >
            <p>
                Please enter a <span className="highlight-border">library name</span> and <span className="highlight-border">upload a .bib file</span> to create a new library.
            </p>
            <TextInput 
                placeholderTexts={["Enter library name"]}
                label="Library Name" 
                value={libraryName}
                onTextChange={setLibraryName}
                useTypewriter={false}
                showButton={false}
                maxLength={128}
                icon="pencil"
                iconStyle="light"
                className="mb-4 mt-8"
            />
            

        </FileUploadModal>
    );
};

const CreateNewLibraryFromPapersModal = ({ isOpen, onClose, selectedPapers, sourceLibraryName, onCreateSuccess }) => {
    const { userId, subscriptionTier } = useUserContext();
    const [newLibraryName, setNewLibraryName] = useState('');
    const [statusUpdates, setStatusUpdates] = useState({});
    const { checkLibraryLimit, fetchLibraries } = useLibraryLimitCheck();

    const updateStatus = (icon, text, color="text-gray-400") => {
        setStatusUpdates({
            icon: icon,
            text: text,
            color: color
        });
      };

    useEffect(() => {
        if (isOpen) {
            setStatusUpdates({});
            setNewLibraryName('');
        }
    }, [isOpen]);

    useEffect(() => {
        const handleError = (data) => {
            updateStatus('exclamation-triangle', data.error, 'text-accent');
            toast.error(data.error, { toastId: 'library-creation-error' });
        };

        socket.on('library_creation_error', handleError);
        socket.on('bib_file_too_large_error', handleError);

        socket.on('library_creation_progress', (data) => {
            updateStatus('robot', `Creating library... ${data.progress.toFixed(2) * 100}%`);
        });
        socket.on('library_creation_success', (data) => {
            updateStatus('check', 'Library created successfully', 'text-secondary');
        });

        return () => {
            socket.off('library_creation_progress');
            socket.off('library_creation_error');
            socket.off('library_creation_success');
            socket.off('bib_file_too_large_error');
        };
    }, []);

    const handleClose = () => {
        setStatusUpdates({});
        setNewLibraryName('');
        onClose();
    };

    const handleCreateLibrary = async () => {
        if (!checkLibraryLimit()) {
            return;
        }

        if (subscriptionTier === 'free' && selectedPapers.length > UsageLimitsFree.maxLibrarySize) {
            toast.error(`Free tier users can only create libraries with up to ${UsageLimitsFree.maxLibrarySize} papers. Please upgrade to create a larger library.`, { toastId: 'library-size-error' });
            return;
        }

        setStatusUpdates({
            icon: 'spinner',
            text: 'Starting library creation...',
            color: 'text-secondary'
        });

        try {
            const [response, success] = await sendPostRequest(
                '/backend/libraries',
                {
                    user_id: userId,
                    library_name: newLibraryName,
                    paper_ids: selectedPapers,
                    subscription_tier: subscriptionTier,
                    is_free_tier: subscriptionTier === 'Free'
                },
                'create-library-from-selection'
            );
            if (success) {
                toast.success("Library created successfully");
                fetchLibraries();
                onCreateSuccess();
                onClose();
            } else {
                throw new Error(response.error || "Failed to create new library");
            }
        } catch (error) {
            console.error(error);
            toast.error(error.message || "Failed to create new library");
            updateStatus('exclamation-triangle', 'Failed to create library', 'text-accent');
        }
    };

    return (
        <Modal isOpen={isOpen} onClose={handleClose}>
            <motion.div 
                className="px-4 py-4"
                initial={{ opacity: 0, y: -20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.3 }}
            >
                <h2 className="text-2xl font-bold mb-4 text-gray-600">
                    <Icon icon="light-building-columns-circle-plus" style="kit" className="mr-2 text-accent" />
                    Create library from selection
                </h2>
                <p className="mb-4 text-gray-600">Create a new library with {selectedPapers.length} papers selected from {sourceLibraryName}</p>
                <TextInput
                    placeholderTexts={["Enter new library name"]}
                    label="New Library Name"
                    value={newLibraryName}
                    onTextChange={setNewLibraryName}
                    useTypewriter={false}
                    showButton={true}
                    maxLength={128}
                    clickOnEnter={true}
                    onButtonClick={handleCreateLibrary}
                    buttonIcon='plus'
                    buttonTooltip='Create Library'
                    buttonProps={{
                        disabled: newLibraryName.length === 0,
                    }}
                    className="mb-4"
                />
                <StatusUpdates {...statusUpdates} isVisible={Object.keys(statusUpdates).length > 0} />
            </motion.div>
        </Modal>
    );
};




const ImportPapersModal = ({ isOpen, onClose, libraryId, libraryName, onImportSuccess }) => {
    const { userId, isFreeTier } = useUserContext();

    const handleUpload = async (file) => {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('user_id', userId);
        formData.append('library_id', libraryId);
        formData.append('is_free_tier', isFreeTier);

        try {
            const [response, success] = await sendPostRequest(
                '/backend/libraries/import',
                formData,
                'import-papers-to-library',
            );

            if (success) {
                toast.success('Papers imported successfully');
                onImportSuccess();
                onClose();
            } else {
                throw new Error(response.error || 'Failed to import papers');
            }
        } catch (error) {
            console.error(error);
            toast.error(error.message || 'Failed to import papers');
        }
    };

    return (
        <FileUploadModal
            isOpen={isOpen}
            onClose={onClose}
            onUpload={handleUpload}
            title="Import Papers to Library"
            description={`Update "${libraryName}" with papers from a .bib file`}
        >
            <p className="mb-4">
                Please <span className="highlight-border">upload a .bib file</span> to add papers to 
                <span className="highlight px-1 ml-1 italic"><Icon icon="building-columns" className="mr-1" />
                {libraryName}
                </span>
            </p>
        </FileUploadModal>
    );
};

export { CreateNewLibraryFromFileModal, CreateNewLibraryFromPapersModal, ImportPapersModal };
