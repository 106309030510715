import React from 'react';
import * as Sentry from "@sentry/react";
import { toast } from 'react-toastify';

import { sendGetRequest } from './Requests';


export const checkSubscriptionStatus = async (user) => {

    try {
      const [response, success] = await sendGetRequest(`/backend/check-subscription?userId=${user.id}`, 'Check Subscription');
      if (!success) {
        throw new Error('Error checking subscription status');
      }
      const data = { 
        status: response.status, 
        plan: response.plan,
        customerId: response.customerId,
        trialEnd: response.trialEnd 
      }
      return data;
    } catch (error) {
      console.error('Error checking subscription status:', error);
      toast.error('Error checking subscription status:', error);
      Sentry.captureException(error);
      throw error;
    }
  };