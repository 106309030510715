import React, { useState } from 'react';
import { motion } from 'framer-motion';

import { useUserContext } from '../UserContext';
import Icon from './Icon';
import {TooltipButton} from "./Buttons.js"
import { useClerk } from '@clerk/clerk-react';

// check if developer env
const isDev = process.env.NODE_ENV === 'development';

let profileURL
let baseURL

if (isDev) {
    profileURL = "https://natural-cowbird-67.accounts.dev/user"
    baseURL = "http://localhost:3000"
} else {
    profileURL = "https://accounts.litsearch.io/user"
    baseURL = "https://litsearch.io"
}


const UserCard = () => {
  const [showDelete, setShowDelete] = useState(false);

  const {
    userName,
    userEmail,
    userPhoto,
    subscriptionTier,
    accountCreated,
  } = useUserContext();

  const { user } = useClerk();

  const handleEdit = () => {
    window.location.href = profileURL; // Redirect to the user profile URL
  }


  const executeDelete = async () => {
    try {
      await user.delete();
      console.log('User account deleted successfully');
      window.location.href = baseURL;
    } catch (error) {
      console.error('Error deleting user account:', error);
    }
  }

  const renderDeleteBox = () => {
    return (
        <motion.div
          className="delete-box border border-red-500 text-red-700 p-4 my-8 w-10/12 mx-auto rounded-lg bg-red-50/50"
          initial={{ opacity: 0, y: -20, scale: 0.9 }}
          animate={{ opacity: 1, y: 0, scale: 1 }}
          exit={{ opacity: 0, y: -20, scale: 0.9 }}
          transition={{ duration: 0.3 }}
        >
            <h2 className="font-bold text-lg">Warning: Account Deletion</h2>
            <p>This action is irreversible and will result in the loss of your data with LitSearch.</p>
            <div className="mt-4">
                <button className="bg-white text-black px-4 py-1 mr-2 rounded border border-gray-600 hover:bg-gray-600 hover:text-white hover:border-white" onClick={() => setShowDelete(false)}>Cancel</button>
                <button className="bg-white text-red-600 px-4 py-1 rounded border border-red-500 hover:bg-red-500 hover:text-white" onClick={executeDelete}>Delete Account</button>
            </div>
            <div className="mt-4 text-xs text-gray-600 border-t border-red-400 pt-4">
                <p>When deleting the account, we retain anonymized usage data to improve the quality of our service.</p>
                <p>We retain Stripe customer information for accurate reporting of financial information. You can contact <a href="mailto:contact@litsearch.io" className="underline">contact@litsearch.io</a> to request that this data be erased.
                All other data will be deleted.</p>
            </div>
        </motion.div>
    )
  }

  const handleDelete = () => {
    setShowDelete(!showDelete)

  }

  return (
    <div className="user-card w-full overflow-visible">
    <div className="flex flex-col lg:flex-row gap-8 items-center w-full overflow-visible">
        <img src={userPhoto} alt={`profile pic`} className="user-photo w-20 h-20 border border-gray-400 rounded-full" />

        <div className="user-details flex flex-col w-full overflow-visible">
            <div className="flex justify-between">
                <p className="text-lg font-semibold mb-1">{userName}</p>
                <div className="flex flex-row gap-2">
                    <TooltipButton tooltip="Edit account" icon="pencil" className="text-gray-500" onClick={handleEdit}/>
                    <TooltipButton tooltip="Delete account" icon="trash" className="text-gray-500 hover:text-red-500" onClick={handleDelete}/>
                </div>
            </div>
        <div className="flex flex-row items-center">
            <Icon icon="envelope" className="mr-1"/>
            <p>{userEmail}</p>
        </div>
        <div className="flex flex-col lg:flex-row lg:gap-6 mt-2 text-sm text-gray-500 overflow-visible">
                <p><Icon icon="calendar" className="mr-1"/>Account Created: {new Date(accountCreated).toLocaleDateString()}</p>
                <p><Icon icon="fire" className="mr-1"/>Subscription Tier: {subscriptionTier || 'Free'}</p>
        </div>
        </div>
    </div>
    {showDelete && renderDeleteBox()}
    </div>
  );
};

export default UserCard;
