import React, { useEffect, useRef, useState } from 'react';
import * as d3 from 'd3';
import { sliderBottom } from 'd3-simple-slider';

const PapersPlot = ({ papers, attribute, color = '#69b3a2', xRange, kernelWidth = 7, height = 100, onRangeChange }) => {
  const svgRef = useRef();
  const containerRef = useRef(null);
  const [dimensions, setDimensions] = useState({ width: 0 });

  // Update dimensions on resize
  useEffect(() => {
    const updateDimensions = () => {
      if (containerRef.current) {
        const { width, _ } = containerRef.current.getBoundingClientRect();
        setDimensions({ width});
      }
    };

    updateDimensions();
    window.addEventListener('resize', updateDimensions);

    return () => window.removeEventListener('resize', updateDimensions);
  }, []);

  useEffect(() => {
    if (!papers || papers.length === 0 || dimensions.width === 0 || dimensions.height === 0) return;

    // Set up dimensions with horizontal padding
    const margin = { top: 0, right: 10, bottom: 40, left: 10 };
    const width = dimensions.width - margin.left - margin.right;
    const plotHeight = height - margin.top - margin.bottom;

    // Clear previous SVG content
    d3.select(svgRef.current).selectAll("*").remove();

    // Create SVG
    const svg = d3.select(svgRef.current)
      .attr("width", width + margin.left + margin.right)
      .attr("height", plotHeight + margin.top + margin.bottom)
      .append("g")
      .attr("transform", `translate(${margin.left},${margin.top})`);

    // Extract data for the selected attribute
    const data = papers.map(paper => attribute === 'score' ? paper[attribute] * 100 : paper[attribute]);

    // Create x scale
    const x = d3.scaleLinear()
      .domain(xRange || d3.extent(data))
      .range([0, width]);


    // Compute KDE with more samples
    const kde = kernelDensityEstimator(kernelGaussian(kernelWidth), x.ticks(200));
    const density = kde(data);

    const y = d3.scaleLinear()
      .domain([0, d3.max(density, d => d[1])])
      .range([plotHeight, 0]);

    // Draw fixed KDE (faint area with black outline)
    svg.append("path")
      .datum(density)
      .attr("class", "kde-area-fixed")
      .attr("fill", color)
      .attr("opacity", 0.2)
      .attr("d", d3.area()
        .x(d => x(d[0]))
        .y0(y(0))
        .y1(d => y(d[1]))
      );

    svg.append("path")
      .datum(density)
      .attr("class", "kde-outline-fixed")
      .attr("fill", "none")
      .attr("stroke", "#000")
      .attr("stroke-width", 0.5)
      .attr("d", d3.line()
        .x(d => x(d[0]))
        .y(d => y(d[1]))
      );

    // Function to update variable KDE
    const updateVariableKDE = (range) => {
      if (!range) return;  // Add this check
      svg.selectAll(".kde-area-variable").remove();

      const filteredDensity = density.filter(d => d[0] >= range[0] && d[0] <= range[1]);

      svg.append("path")
        .datum(filteredDensity)
        .attr("class", "kde-area-variable")
        .attr("fill", color)
        .attr("opacity", 1)
        .attr("d", d3.area()
          .x(d => x(d[0]))
          .y0(y(0))
          .y1(d => y(d[1]))
        );
    };

    // Initial variable KDE
    updateVariableKDE(x.domain());

    // Add X axis
    svg.append("g")
      .attr("transform", `translate(0,${plotHeight})`)
      .call(d3.axisBottom(x).ticks(4).tickFormat(d3.format('d')).tickSize(3));

    // Add slider
    const slider = sliderBottom()
      .min(x.domain()[0])
      .max(x.domain()[1])
      .width(width-6)
      .tickFormat(() => '') // Hide tick labels
      .ticks(-1)
      .default(x.domain())
      .fill(color)
      .displayValue(false) // Hide the display value
      .handle(
        d3.symbol()
          .type(d3.symbolCircle)
          .size(100)
      )
      .on('onchange', (val) => {
        updateVariableKDE(val);
        onRangeChange(attribute, val); // Call this function when the range changes
      });

    svg.append("g")
      .attr("transform", `translate(3,${plotHeight + 25})`)
      .call(slider);

  }, [papers, attribute, dimensions, color, xRange, kernelWidth,]);

  return (
    <div ref={containerRef} className="plot-container w-[28vw] lg:w-full h-full">
      <svg ref={svgRef} className="w-full h-full mx-auto"></svg>
    </div>
  );
};



// Replace the existing kernelEpanechnikov function with this Gaussian kernel
function kernelGaussian(k) {
  return (v) => {
    return Math.exp(-.5 * v * v / k / k) / Math.sqrt(2 * Math.PI * k * k);
  };
}

// Update the kernelDensityEstimator function for smoother interpolation
function kernelDensityEstimator(kernel, X) {
  return function(V) {
    return X.map(x => [x, d3.mean(V, v => kernel(x - v))]);
  };
}

export default PapersPlot;
