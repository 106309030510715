import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { toast } from 'react-toastify';


import Icon from '../elements/Icon';
import { useUserContext } from '../UserContext';
import sendPostRequest from '../scripts/Requests';




const AddToLibrarySelector = ({ paper, onClose, selectedLibraries, setSelectedLibraries }) => {
  const { libraries, userId } = useUserContext();
  const [isLoading, setIsLoading] = useState(false);

  const handleLibraryToggle = (libraryId) => {
    setSelectedLibraries(prev => 
      prev.includes(libraryId) 
        ? prev.filter(id => id !== libraryId)
        : [...prev, libraryId]
    );
  };

  const handleAddToLibraries = async () => {
    setIsLoading(true);

    let successAll = true;
    for (const libraryId of selectedLibraries) {
      try {
        const [response, success, error] = await sendPostRequest(
          `/backend/libraries/${libraryId}/papers`,
          { paper_ids: [paper.paperId], user_id: userId},
          'add-papers-to-library'
        );

        if (!success) {
          successAll = false;
          throw new Error(error || "Failed to add paper");
        }
      } catch (error) {
        console.error(error);
        toast.error(`Failed to add to library: ${libraries.find(lib => lib.id === libraryId).name}`);
      } 
    }

    if (successAll) {
      toast.success(`Paper added`);
    } else {
      toast.error(`Failed to add paper`);
    }
    setIsLoading(false);
    onClose();
  };

  return (
    <motion.div
      initial={{ height: 0 }}
      animate={{ height: 'auto' }}
      exit={{ height: 0 }}
      className="add-to-library-selector bg-white border border-gray-200 rounded-md  overflow-auto mt-2 mb-8 px-2"
    >
      <div className="p-4">
        <h3 className="font-semibold mb-2">Add to Libraries</h3>
        
        <div className="max-h-[30vh] overflow-y-auto">
          {libraries.length > 0 ? libraries.map(library => (
            <div key={library.id} className="flex items-center mb-1 ml-2 cursor-pointer text-sm" onClick={() => handleLibraryToggle(library.id)}>
              <Icon 
                icon={selectedLibraries.includes(library.id) ? 'check-square' : 'square'} 
                className="mr-1 text-secondary"
              />
              <span>{library.name}</span>
            </div>
          )) : (<>
            <p className="text-accent"><Icon icon="triangle-exclamation" className="inline-block mr-1 text-warning" /> No libraries found</p>
            <p>Head to 
                <span className="highlight mx-1">
                <Icon icon="building-columns" className="inline-block mr-1" />My Libraries </span>to create one</p>
            </>
          )
          }
        </div>

        {/* buttons */}
        {libraries.length > 0 && (
        <div className="mt-2 flex justify-start gap-2 text-sm">
        <button
            onClick={handleAddToLibraries}
            disabled={selectedLibraries.length === 0 || isLoading}
            className="px-2 py-1 bg-secondary text-white rounded disabled:bg-gray-300 disabled:text-gray-500 hover:bg-secondary-dark"
          >
            {isLoading ? 'Adding...' : (
                selectedLibraries.length === 0 ? 'Select a library' : <p>
                    <Icon icon="circle-plus" className="mr-2" />
                    {`Add (${selectedLibraries.length})`}
                </p>
            )}
          </button>

          <button
            onClick={onClose}
            className="mr-2 px-2 py-1 hover:underline"
          >
            Cancel
          </button>

          </div>
        )}
      </div>
    </motion.div>
  );
};

export default AddToLibrarySelector;
